<script>
import Vue from 'vue'
import CustomScrollbar from '@game/Level02/components/CustomScrollbar.vue';
import Dialog from '@game/Level02/components/Dialog.vue';
import { tLevel2 } from '@game/utils';
import avatar from '@game/assets/img/avatar.png';
import plusDisable from '@game/Level02/assets/img/plus-disable.png';
import plusEnable from '@game/Level02/assets/img/plus-enable.png';
import scrollSound from '@game/assets/media/level02/scroll.mp3';
import selectMailSound from '@game/assets/media/level02/select-mail.mp3';
import selectErrorSound from '@game/assets/media/level01/select-error.wav';
import { audioMixinLevel1 } from '@game/mixins/audioMixin.js';


export default Vue.extend({
  name: 'Scene4',
  mixins: [audioMixinLevel1],
  components: {
    CustomScrollbar,
    Dialog,
  },
  data() {
    return {
      title: tLevel2('scene4.title'),
      cardTitle: tLevel2('scene4.cardTitle'),
      mailTitle: tLevel2('scene4.mailTitle'),
      mailSender: tLevel2('scene4.mailSender'),
      mailSenderPlus: plusDisable,
      mailSurveyPlus: plusDisable,
      mailSubmitPlus: plusDisable,
      mailAttachmentPlus: plusDisable,
      mailTipsPlus: plusDisable,
      mailText: tLevel2('scene4.mailText'),
      buttonText: tLevel2('scene4.buttonText'),
      isShowDialog: false,
    }
  },
  methods: {
    mailSenderPlusClick(event) {
      event.stopPropagation();

      this.mailSenderPlus = this.mailSenderPlus === plusDisable ? plusEnable : plusDisable
      // if (this.mailSenderPlus === plusEnable) {
      //   this.$audioManager.play('other', selectMailSound)
      // } else {
      //   this.$audioManager.play('other', selectErrorSound)
      // }
    },
    mailSurveyClick(event) {
      event.stopPropagation();

      this.mailSurveyPlus = this.mailSurveyPlus === plusDisable ? plusEnable : plusDisable
      // if (this.mailSurveyPlus === plusEnable) {
      //   this.$audioManager.play('other', selectMailSound)
      // } else {
      //   this.$audioManager.play('other', selectErrorSound)
      // }
    },
    mailSubmitPlusClick(event) {
      event.stopPropagation();

      this.mailSubmitPlus = this.mailSubmitPlus === plusDisable ? plusEnable : plusDisable
      // if (this.mailSubmitPlus === plusEnable) {
      //   this.$audioManager.play('other', selectMailSound)
      // } else {
      //   this.$audioManager.play('other', selectErrorSound)
      // }
    },
    mailAttachmentPlusClick(event) {
      event.stopPropagation();

      this.mailAttachmentPlus = this.mailAttachmentPlus === plusDisable ? plusEnable : plusDisable
      // if (this.mailAttachmentPlus === plusEnable) {
      //   this.$audioManager.play('other', selectMailSound)
      // } else {
      //   this.$audioManager.play('other', selectErrorSound)
      // }
    },
    mailTipsPlusClick(event) {
      event.stopPropagation();

      this.mailTipsPlus = this.mailTipsPlus === plusDisable ? plusEnable : plusDisable
      // if (this.mailTipsPlus === plusEnable) {
      //   this.$audioManager.play('other', selectMailSound)
      // } else {
      //   this.$audioManager.play('other', selectErrorSound)
      // }
    },

    otherSelected() {
      // this.$audioManager.play('other', selectErrorSound)
    },

    onForward(event) {
      event.stopPropagation();

      this.$store.commit('completeTask')
      this.$audioManager.playButtonNext(() => {
        this.$router.push({ name: 'L2Scene5' })
      })
    },

    onSubmit(event) {
      event.stopPropagation();

      this.isShowDialog = true
      if (this.isAllSelected) {
        this.$audioManager.play('other', selectMailSound)
      } else {
        this.$audioManager.play('other', selectErrorSound)
      }
      // this.$audioManager.playButtonNext()
    },

    scrollUpdate() {
      this.$audioManager.play('other', scrollSound)
    }
  },
  computed: {
    isNoneSelected() {
      return this.mailSenderPlus === plusDisable && this.mailSurveyPlus === plusDisable && this.mailSubmitPlus === plusDisable && this.mailAttachmentPlus === plusDisable && this.mailTipsPlus === plusDisable;
    },
    isAllSelected() {
      return this.mailSenderPlus === plusEnable && this.mailSurveyPlus === plusEnable && this.mailSubmitPlus === plusEnable && this.mailAttachmentPlus === plusEnable && this.mailTipsPlus === plusEnable;
    }
  },
  render() {
    return (
      <div class="container" onClick={this.otherSelected}>
        <AudioIcon />
        <h3 class="title">{this.title}</h3>
        <div class="card">
          <div class="card-title">{this.cardTitle}</div>
          <div class="card-content">
            <div class="mail-head">
              <img class="avatar" src={avatar} alt="" />
              <div class="content">
                <div class="mail-title">{this.mailTitle}</div>
                <div class="mail-sender">
                  {this.mailSender[0]}<span onClick={this.mailSenderPlusClick}>{this.mailSender[1]}</span>{this.mailSender[2]}
                  <img class="plus" src={this.mailSenderPlus} alt="" onClick={this.mailSenderPlusClick} />
                </div>
              </div>
            </div>
            <CustomScrollbar scrollUpdate={this.scrollUpdate}>
              <div class="mail-content">
                {this.mailText.map((item, index) => {
                  if (typeof item === 'string') {
                    if (index === 5) {
                      return <p key={index} onClick={this.mailSurveyClick}>{item}<img class="plus" src={this.mailSurveyPlus} /></p>
                    } else if (index === 9) {
                      return <p key={index} onClick={this.mailAttachmentPlusClick}>{item}<img class="plus" src={this.mailAttachmentPlus} /></p>
                    } else if (index === 12) {
                      return <p key={index} onClick={this.mailTipsPlusClick}>{item}<img class="plus" src={this.mailTipsPlus} /></p>
                    }
                    return <p key={index}>{item}</p>
                  } else {
                    return <p key={index} onClick={this.mailSubmitPlusClick}>{item[0]}<span>{item[1]}</span><img class="plus" src={this.mailSubmitPlus} /><span>{item[2]}</span>{item[3]}</p>
                  }
                })}
              </div>
            </CustomScrollbar>
          </div>
          <div class={`button ${this.isNoneSelected ? 'button-disable' : ''}`} onClick={this.onSubmit}>{this.buttonText}</div>
        </div>
        {
          this.isShowDialog ? <transition appear enter-active-class="animate__animated animate__fadeIn"
            leave-active-class="animate__animated animate__fadeOut"><Dialog isAllSelected={this.isAllSelected} viewAnswer={this.onForward} /></transition> : null
        }
      </div >
    )
  }
})
</script>

<style lang="scss" rel='stylesheet/scss' scoped>
.container {
  /* animation */
  position: absolute;

  width: 100%;
  height: 100%;
  background-image: url(@game/Level02/assets/img/mail-bg.jpg);
  background-size: contain;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.title {
  width: 6rem;
  height: 2rem;
  background-color: #66a261;
  border: 3px solid #fff;
  border-radius: 1rem;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}

.card {
  box-sizing: border-box;
  width: 82%;
  min-height: 80%;
  max-height: 80%;
  border-radius: 1.5rem;
  background-color: #fff;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;

  .plus {
    width: 1.2rem;
  }

  .card-title {
    background-color: #4a4a4a;
    min-height: 3rem;
    color: #d7cd81;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 2rem;
    text-align: center;
    align-self: stretch;
    margin-bottom: auto;
  }

  .card-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 15px 0;
    padding-right: 5px;

    .mail-head {
      padding: 0 1rem;
      // padding-bottom: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;

      .avatar {
        width: 2.5rem;
      }

      .content {
        margin-left: .7rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .mail-title {
          font-size: .8rem;
          font-weight: bold;
          letter-spacing: 1px;
        }

        .mail-sender {
          font-size: .7rem;
          // color: #d9d9d9;
          position: relative;

          span {
            // color: #6466f6;
            cursor: pointer;
          }
        }

        .plus {
          position: absolute;
          right: -1.5rem;
          cursor: pointer;
        }
      }
    }

    .mail-content {
      height: 380px;
      // overflow-y: scroll;
      padding: 1rem 1rem;
      padding-top: 0;
      font-weight: bold;
      letter-spacing: 1px;

      p {
        font-size: .8rem;
        margin-top: 1rem;
        margin-left: .5rem;
      }

      p:first-child {
        margin-top: 0;
      }

      p:nth-child(6) {
        // color: #56bcf1;
        cursor: pointer;
      }

      p:nth-child(6) img {
        vertical-align: bottom;
      }

      p:nth-child(9) span {
        // color: #e1524c;
        cursor: pointer;
      }

      p:nth-child(9) img {
        vertical-align: bottom;
        cursor: pointer;
      }

      p:nth-child(10),
      p:nth-child(10) img {
        // color: #7cc376;
        vertical-align: bottom;
        cursor: pointer;
      }

      p:nth-child(13),
      p:nth-child(13) img {
        text-decoration: underline;
        // color: #f3ae41;
        vertical-align: bottom;
        cursor: pointer;
      }
    }
  }

  .button {
    box-sizing: border-box;
    background-color: #50a459;
    padding: 5px 10px;
    border-radius: .5rem;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;

    cursor: pointer;

    &:hover {
      opacity: .8;
    }
  }

  .button-disable {
    pointer-events: none;
    background-color: #d9d9d9;
    color: #000;
  }
}
</style>