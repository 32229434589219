<template>
    <div class="shylock1-dialog-container">
        <div class="shylock1-modal">
            <p>{{ text }}</p>
            <img class="img-backward" src="../../assets/img/arrow.png" alt="" @click="onBackward">
            <img class="img-forward" src="../../assets/img/arrow.png" alt="" @click="onForward">
        </div>
        <img class="img-shylock1" src="../assets/img/shylock-2.png" alt="">
    </div>
</template>

<script>
import { tLevel4 } from '../../utils';
export default {
    props: ['backward', 'forward'],
    data() {
        return {
            text: tLevel4('scene4.shylock1'),
        }
    },
    methods: {
        onBackward() {
            this.backward();
        },
        onForward() {
            this.forward();
        }
    },
}
</script>

<style lang="scss" scoped>
.shylock1-dialog-container {
    width: 83%;
    height: 15%;

    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 70px;

    z-index: 1001;

    .shylock1-modal {
        box-sizing: border-box;
        padding: 10px 30px;
        width: 100%;
        height: 100%;
        background-color: #fff;
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 2;

        p {
            margin: 0;
        }

        .img-forward,
        .img-backward {
            bottom: -0.3rem;
            cursor: pointer;

            &:hover {
                opacity: .8;
            }
        }

        .img-forward {
            right: -0.5rem;
        }

        .img-backward {
            left: -0.5rem;
            transform: rotate(180deg);
        }
    }

    img {
        position: absolute;
        width: 2.5rem;
    }

    .img-shylock1 {
        width: 70%;
        top: -120%;
        left: -8%;
        z-index: 1;
    }
}
</style>