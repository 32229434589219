<template>
    <div class="container">
        <AudioIcon />
        <h1 class="title">{{ title }}</h1>
        <div class="text-container">
            <ul>
                <li v-for="(item, index) in answerList" :key="index"><span>{{ item.title }}</span>{{ item.text }}</li>
            </ul>
            <div class="button" @click="continueGame">{{ buttonText }}</div>
        </div>
    </div>
</template>

<script>
import { tLevel2 } from '@game/utils';
import { audioMixinLevel1 } from '@game/mixins/audioMixin.js';

export default {
    name: 'Scene5',
    mixins: [audioMixinLevel1],
    beforeCreate() {
        this.$store.commit('resetTask')
    },
    data() {
        return {
            title: tLevel2('scene5.title'),
            answerList: tLevel2('scene5.answerList'),
            buttonText: tLevel2('scene5.buttonText'),
        }
    },
    computed: {
        password1Class() {
            return `password1 ${this.isPassword1Selected ? 'selected' : ''}`
        },
        password2Class() {
            return `password2 ${this.isPassword2Selected ? 'selected' : ''}`
        }
    },
    methods: {
        continueGame() {
            this.$audioManager.playButtonNext(() => {
                this.$router.push({ name: 'L2Scene6' })
            })
        },
    }
}

</script>

<style lang="scss" scoped>
* {
    box-sizing: border-box;
}

.container {
    /* animation */
    position: absolute;

    width: 100%;
    height: 100%;

    background-image: url(@game/assets/img/kepu.jpg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .title {
        box-sizing: border-box;
        position: absolute;
        bottom: 700px;
        border: 2px solid #fff;
        border-radius: 25px;
        max-width: 90%;
        background-color: #50a459;
        color: #fff;
        font-size: 1.2rem;
        font-weight: normal;
        padding: 5px 20px;
        text-align: center;
    }

    .text-container {
        width: 60%;
        font-size: .9rem;
        font-weight: normal;
        line-height: 120%;
        position: absolute;
        top: 130px;
        bottom: 130px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0;
        gap: 15px;

        ul {
            min-height: 500px;
            max-height: 500px;
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            overflow: auto;
            gap: 10px;

            li {
                span {
                    color: #66a261;
                }
            }
        }

        .button {
            max-width: 90%;
            padding: 5px 10px;
            background-color: #50a459;
            border-radius: .5rem;
            text-align: center;
            color: #fff;
            font-size: 1rem;
            cursor: pointer;

            &:hover {
                opacity: 0.8;
            }
        }
    }
}
</style>