<template>
    <div class="alex2-dialog-container">
        <div class="alex2-modal">
            <p>{{ text }}</p>
            <div class="button" @click="onForward">{{ buttonText }}</div>
        </div>
        <img class="img-alex2" src="../assets/img/alex-2.png" alt="">
    </div>
</template>

<script>
import { tLevel4 } from '../../utils';
export default {
    props: ['forward'],
    data() {
        return {
            text: tLevel4('scene4.alex2'),
            buttonText: tLevel4('scene4.buttonText'),
        }
    },
    methods: {
        onBackward() {
            this.backward();
        },
        onForward() {
            this.forward();
        }
    },
}
</script>

<style lang="scss" scoped>
.alex2-dialog-container {
    width: 83%;
    min-height: 15%;

    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 70px;

    z-index: 1001;

    .alex2-modal {
        box-sizing: border-box;
        padding: 20px 30px;
        width: 100%;
        height: 100%;
        background-color: #fff;
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 2;

        p {
            margin: 0;
        }

        .button {
            margin-top: 1rem;
            padding: 5px 10px;
            cursor: pointer;
            background-color: #66a261;
            color: #fff;
            text-align: center;
            border-radius: .4rem;

            &:hover {
                opacity: .8;
            }
        }


    }

    .img-alex2 {
        position: absolute;
        width: 70%;
        top: -135%;
        left: 7%;
        z-index: 1;
    }
}
</style>