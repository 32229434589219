<template>
  <div class="cover">
    <AudioIcon/>
    <h1 class="title">{{ title }}</h1>
    <transition-group appear name="animate__animated animate__bounce" tag="div" :enter-active-class="enterActiveClass"
      :leave-active-class="leaveActiveClass">
      <div class="character-alex" v-show="isAlex" key="alex">
        <img src="./assets/img/character-selection/alex.png" alt="">
      </div>
      <div class="character-chunlong" v-show="!isAlex" key="chunlong">
        <img src="./assets/img/character-selection/chunlong.png" alt="">
      </div>
    </transition-group>
    <div class="name" :class="{ 'disable-name': !isAlex }" @click="start">{{ isAlex ? alex : chunLong }}</div>
    <div class="switch-button">
      <img class="left" :class="{ 'no-hover': isAlex }" :src="leftButton" alt="" @click="viewAlex">
      <img class="right" :class="{ 'no-hover': !isAlex }" :src="rightButton" alt="" @click="viewChunLong">
    </div>
  </div>
</template>

<script>
import 'animate.css';
import leftEnable from './assets/img/character-selection/left-enable.png';
import leftDisable from './assets/img/character-selection/left-disable.png';
import rightEnable from './assets/img/character-selection/right-enable.png';
import rightDisable from './assets/img/character-selection/right-disable.png';
import characterSelection from './assets/media/character-selection.mp3';
import {audioMixinLevel1} from './mixins/audioMixin.js';

export default {
  mixins: [audioMixinLevel1],
  name: 'GamePeople',
  props: {
    msg: String
  },
  data() {
    return {
      title: this.$t('miniGame.characterSelection.title'),
      alex: this.$t('miniGame.characterSelection.alex'),
      chunLong: this.$t('miniGame.characterSelection.chunLong'),
      isAlex: true,
    }
  },
  computed: {
    leftButton() {
      return this.isAlex ? leftDisable : leftEnable;
    },
    rightButton() {
      return this.isAlex ? rightEnable : rightDisable;
    },
    enterActiveClass() {
      // return this.isAlex ? 'animate__fadeInRight' : 'animate__fadeInLeft';
      // return this.isAlex ? 'animate__fadeInDown' : 'animate__fadeInUp';
      return 'animate__fadeInDown';
    },
    leaveActiveClass() {
      // return this.isAlex ? 'animate__fadeOutLeft' : 'animate__fadeOutRight';
      // return this.isAlex ? 'animate__fadeOutUp' : 'animate__fadeOutDown';
      return 'animate__fadeOutUp';
    },
  },

  methods: {
    viewChunLong() {
      this.$audioManager.playButtonNext()
      this.isAlex = false;
    },
    viewAlex() {
      this.isAlex = true;
      this.$audioManager.playButtonPrevious()
    },
    start() {
      this.$audioManager.play('characterSelection', characterSelection)
      setTimeout(() => {
        this.$router.push({ name: 'CharacterProfile' })
      }, 200);
    },
  }
}
</script>

<style lang="scss" scoped>
.cover {
  /* animation */
  position: absolute;

  width: 100%;
  height: 100%;
  background-image: url(./assets/img/character-selection/background.jpg);
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;

  .title {
    color: #4f7a6c;
    position: absolute;
    bottom: 550px;
    text-align: center;
  }

  .character-alex {
    position: absolute;
    left: 50px;
    bottom: 245px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 280px;
    }
  }

  .character-chunlong {
    position: absolute;
    left: 50px;
    bottom: 245px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 200px;
    }
  }

  .name {
    box-sizing: border-box;
    position: absolute;
    bottom: 150px;
    background-color: #66a261;
    color: #fff;
    padding: 8px 15px;
    border-radius: 1rem;
    cursor: pointer;

    &:hover {
      opacity: .8;
    }
  }

  .disable-name {
    background-color: #b5c0c2;
    pointer-events: none;
  }

  .switch-button {
    position: absolute;
    bottom: 70px;
    width: 5rem;
    display: flex;
    justify-content: space-between;

    .left,
    .right {
      width: 2rem;
      cursor: pointer;

      &:hover {
        opacity: .8;
      }
    }

    .no-hover {
      pointer-events: none;
    }
  }
}
</style>
