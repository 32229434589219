<template>
  <div class="container">
    <AudioIcon />
    <h3 class="head">{{ headTitle }}</h3>
    <div class="content">
      <div class="message-list" ref="messageList">
        <div v-for="(message, index) in messages" :key="index" class="message animate__animated animate__fadeIn">
          <img :src="message.avatar" alt="" class="avatar">
          <div class="info">
            <div class="title">{{ message.title }}</div>
            <div class="text" v-if="index === 5">
              <div class="wrapper">
                {{ message.text[0] }}
                <span>{{ message.text[1] }}</span>
              </div>
              <img src="@game/assets/img/level05/official-account.png" alt="">
            </div>
            <div class="text" v-else-if="index === 6 || index === 7 || index === 8">
              {{ message.text }}
              <img src="@game/assets/img/level05/doc.png" alt="">
            </div>
            <div class="text" v-else-if="index === 11">
              {{ message.text[0] }} <span>{{ message.text[1] }}</span>
            </div>
            <div class="text" v-else-if="index === 16">
              <img src="@game/assets/img/level05/screenshot-1.png" alt="" />
            </div>
            <div class="text" v-else-if="index === 20">{{ message.text }}<img
                src="@game/assets/img/level05/screenshot-2.png" alt="" /></div>
            <div class="text" v-else>{{ message.text }}</div>
          </div>
        </div>

        <div class="dialog" v-if="isDialogShow">
          <div class="option-a" @click="onSelectA" :style="styleA">{{ optionA }}</div>
          <div class="option-b" @click="onSelectB" :style="styleB">{{ optionB }}</div>
        </div>
      </div>
    </div>
    <div class="footer" @click="onForward" :style="pseudoElementStyle">
      <img src="@game/assets/img/level05/reply-box.png" alt="">
    </div>
  </div>
</template>

<script>
import { tLevel5 } from '@game/utils';
import selectSound from '@game/assets/media/level05/select.mp3';
import { audioMixinLevel5 } from '@game/mixins/audioMixin.js';

import avatarCharles from '@game/assets/img/level05/charles.png';
import avatarA from '@game/assets/img/level05/a.png';
import avatarB from '@game/assets/img/level05/b.png';
import avatarC from '@game/assets/img/level05/c.png';
import avatarGirami from '@game/assets/img/level05/girami.png';
import avatarFrank from '@game/assets/img/level05/frank.png';


import newMessageSound from '@game/assets/media/level05/create-group.mp3';

export default {
  name: 'Scene4',
  props: {
    isShow: {
      type: Boolean,
    }
  },
  mixins: [audioMixinLevel5],
  mounted() {
    this.startAddingMessages()
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  },
  data() {
    return {
      headTitle: tLevel5('headTitle'),
      charles: tLevel5('members.charles'),
      a: tLevel5('members.a'),
      b: tLevel5('members.b'),
      c: tLevel5('members.c'),
      charlesText1: tLevel5('scene4.charlesText'),
      aText1: tLevel5('scene4.aText'),
      bText1: tLevel5('scene4.bText'),
      cText1: tLevel5('scene4.cText'),

      girami: tLevel5('members.girami'),
      giramiText1: tLevel5('scene5.giramiText1'),
      giramiText2: tLevel5('scene5.giramiText2'),

      giramiText3: tLevel5('scene6.giramiText1'),
      giramiText4: tLevel5('scene6.giramiText2'),
      giramiText5: tLevel5('scene6.giramiText3'),
      giramiText6: tLevel5('scene6.giramiText4'),

      frank: tLevel5('members.frank'),
      frankText1: tLevel5('scene7.frankText1'),
      frankText2: tLevel5('scene7.frankText2'),
      aText2: tLevel5('scene7.aText'),
      bText2: tLevel5('scene7.bText'),
      cText2: tLevel5('scene7.cText'),

      frankText3: tLevel5('scene8.frankText1'),
      frankText4: tLevel5('scene8.frankText2'),
      charlesText2: tLevel5('scene8.charlesText'),

      bText3: tLevel5('scene9.bText1'),
      bText4: tLevel5('scene9.bText2'),
      frankText5: tLevel5('scene9.frankText'),
      aText3: tLevel5('scene9.aText'),
      cText3: tLevel5('scene9.cText'),

      messages: [],


      optionA: tLevel5('scene9.dialog.a'),
      optionB: tLevel5('scene9.dialog.b'),
      isDialogShow: this.isShow,
      isSelectedA: false,
      isSelectedB: false,

      interval: null,
    }
  },
  computed: {
    pseudoElementStyle() {
      return (this.isSelectedA || this.isSelectedB) ? { '--pseudo-display': 'block', '--pointer-events': 'all' } : { '--pseudo-display': 'none', '--pointer-events': 'none' };
    },
    styleA() {
      if (this.isSelectedA) {
        return {
          backgroundColor: '#538adb',
          outline: '2px solid #fff',
        }
      } else {
        return {
          backgroundColor: '#ffffff',
          outline: 'none',
        }
      }
    },
    styleB() {
      if (this.isSelectedB) {
        return {
          backgroundColor: '#538adb',
          outline: '2px solid #fff',
        }
      } else {
        return {
          backgroundColor: '#ffffff',
          outline: 'none',
        }
      }
    }
  },
  methods: {
    // onForward() {
    //   this.$audioManager.playButtonNext(() => {
    //     this.$router.push({ name: 'L5Scene5' })
    //   })
    // },

    onSelectA() {
      this.$audioManager.play('other', selectSound)
      this.isSelectedB = false
      this.isSelectedA = true
    },
    onSelectB() {
      this.$audioManager.play('other', selectSound)
      this.isSelectedA = false
      this.isSelectedB = true
    },
    onForward() {
      if (!this.isDialogShow) {
        this.isDialogShow = true;
        return;
      }

      this.$store.commit('completeTask')
      this.$audioManager.playButtonNext(() => {
        if (this.isSelectedA) {
          this.$router.push({ name: 'L5Scene10' })
        } else if (this.isSelectedB) {
          this.$router.push({ name: 'L5Scene15' })
        }
      })
    },

    startAddingMessages() {
      const newMessages = [{
        id: 0,
        avatar: avatarCharles,
        title: this.charles,
        text: this.charlesText1
      }, {
        id: 1,
        avatar: avatarA,
        title: this.a,
        text: this.aText1
      }, {
        id: 2,
        avatar: avatarB,
        title: this.b,
        text: this.bText1
      }, {
        id: 3,
        avatar: avatarC,
        title: this.c,
        text: this.cText1
      }, {
        id: 4,
        avatar: avatarGirami,
        title: this.girami,
        text: this.giramiText1
      }, {
        id: 5,
        avatar: avatarGirami,
        title: this.girami,
        text: this.giramiText2
      },
      {
        id: 6,
        avatar: avatarGirami,
        title: this.girami,
        text: this.giramiText3
      }, {
        id: 7,
        avatar: avatarGirami,
        title: this.girami,
        text: this.giramiText4
      }, {
        id: 8,
        avatar: avatarGirami,
        title: this.girami,
        text: this.giramiText5
      }, {
        id: 9,
        avatar: avatarGirami,
        title: this.girami,
        text: this.giramiText6
      }, {
        id: 10,
        avatar: avatarFrank,
        title: this.frank,
        text: this.frankText1
      }, {
        id: 11,
        avatar: avatarFrank,
        title: this.frank,
        text: this.frankText2
      }, {
        id: 12,
        avatar: avatarA,
        title: this.a,
        text: this.aText2
      }, {
        id: 13,
        avatar: avatarB,
        title: this.b,
        text: this.bText2
      }, {
        id: 14,
        avatar: avatarC,
        title: this.c,
        text: this.cText2
      }, {
        id: 15,
        avatar: avatarFrank,
        title: this.frank,
        text: this.frankText3
      },
      {
        id: 16,
        avatar: avatarFrank,
        title: this.frank,
      },
      {
        id: 17,
        avatar: avatarCharles,
        title: this.charles,
        text: this.charlesText2
      },
      {
        id: 18,
        avatar: avatarFrank,
        title: this.frank,
        text: this.frankText4
      }, {
        id: 19,
        avatar: avatarB,
        title: this.b,
        text: this.bText3
      },
      {
        id: 20,
        avatar: avatarB,
        title: this.b,
        text: this.bText4
      },
      {
        id: 21,
        avatar: avatarFrank,
        title: this.frank,
        text: this.frankText5
      },
      {
        id: 22,
        avatar: avatarA,
        title: this.a,
        text: this.aText3
      },
      {
        id: 23,
        avatar: avatarC,
        title: this.c,
        text: this.cText3
      },
      ]

      let index = 0;
      const addMessage = () => {
        if (index < newMessages.length) {
          this.$audioManager.play('other', newMessageSound)
          this.messages.push(newMessages[index]);
          index++;
          this.$nextTick(() => {
            this.scrollToBottom();
          });
        } else {
          clearInterval(this.interval)
          this.interval = null
          this.isDialogShow = true
        }
      };

      addMessage()
      this.interval = setInterval(() => {
        addMessage()
      }, 2000);
    },
    scrollToBottom() {
      const container = this.$refs.messageList;
      container.scrollTop = container.scrollHeight;
    },
  }
}
</script>

<style lang="scss" scoped>
.container {
  /* animation */
  position: absolute;

  width: 100%;
  height: 100%;
  background-image: url(@game/assets/img/level05/scene-4-bg.jpg);
  background-size: contain;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;


  .head {
    width: 100%;
    min-height: 60px;
    background-color: #8fcef5;
    border-radius: 20px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    color: #1d3082;
    font-weight: normal;
    margin: 0;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content {
    margin-top: 10px;
    flex: 1;
    box-sizing: border-box;
    width: 100%;
    // padding: 30px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    overflow-y: hidden;
    position: relative;
    scroll-behavior: smooth;

    .message-list {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      gap: 30px;
      position: absolute;
      // top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 30px 30px;


      .message {
        display: flex;
        // justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;

        img {
          margin-top: -5px;
          width: 50px;
        }

        .info {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          align-self: flex-end;
          gap: 10px;

          .title {
            color: #aad8f7;
          }

          .text {
            box-sizing: border-box;
            background-color: #afdffb;
            color: #334b94;
            padding: 10px 20px;
            border-radius: 10px;
          }
        }
      }

      .message:nth-child(6) .text {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        .wrapper span {
          display: block;
          color: #7890da;
        }

        img {
          margin: 5px auto;
        }
      }

      .message:nth-child(7),
      .message:nth-child(8),
      .message:nth-child(9) {
        .text {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 10px;

          img {
            margin: 5px auto;
          }
        }
      }

      .message:nth-child(12) {
        .text {
          span {
            color: #2649d6;
            text-decoration: underline
          }
        }
      }

      .message:nth-child(17) {

        >img {
          visibility: hidden;
        }

        .text {
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 15px 15px;

          img {
            width: 150px;
          }
        }
      }

      .message:nth-child(21) {
        .text {
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          padding: 8px 10px;

          img {
            margin-top: 5px;
            width: 130px;
          }
        }
      }

      .dialog {
        width: 100%;
        height: 220px;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .option-a,
        .option-b {
          flex: 1;
          box-sizing: border-box;
          border-radius: 15px;
          margin-top: 10px;
          padding: 15px 18px;
          width: 80%;
          background-color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          &:hover {
            opacity: .8;
          }
        }

      }
    }
  }

  .footer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    --pseudo-display: block;
    --pointer-events: none;
    pointer-events: var(--pointer-events);

    img {
      width: 100%;
    }

    &:hover {
      opacity: .8;
    }

    &::after {
      content: '';
      display: var(--pseudo-display);
      width: 45px;
      height: 45px;
      background-image: url(@game/assets/img/level05/arrow-upload.png);
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      position: absolute;
      top: 20px;
      right: 35px;
    }
  }
}
</style>