<template>
  <div class="overlay">
    <div class="dialog">
      <div class="title-bar">
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
      </div>
      <div class="content">
        <img src="../assets/img/clock2.png" alt="" class="clock-icon">
        <p>{{ text }}</p>
        <div class="button-wrapper">
          <div class="button" @click="restartGame">{{ buttonText1 }}</div>
          <div class="button" @click="continueGame">{{ buttonText2 }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TimeoutDialog',
  props: {
    restartGame: {
      type: Function,
      required: true,
    },
    continueGame: {
      type: Function,
      required: true,
    }
  },
  data() {
    return {
      text: this.$t('miniGame.timeoutDialog.text'),
      buttonText1: this.$t('miniGame.timeoutDialog.buttonText1'),
      buttonText2: this.$t('miniGame.timeoutDialog.buttonText2'),
    }
  },
}
</script>

<style lang="scss" scoped>
.overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba($color: #fff, $alpha: .5);

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;

  .dialog {
    box-sizing: border-box;
    width: 80%;
    background-color: #fffdf8;
    border: 2px solid #ffab55;
    border-radius: 10px;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .title-bar {
      width: 100%;
      height: 30px;
      background-color: #ffab55;

      display: flex;
      align-items: center;

      .circle {
        margin-left: 15px;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: #fff;
      }
    }

    .content {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 20px 30px;
      gap: 15px;

      .clock-icon {
        width: 80px;
      }

      p {
        margin: 0;
        padding: 0 30px;
        text-align: center;
      }

      .button-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;

        .button {
          padding: 5px 10px;
          background-color: #66a261;
          border-radius: 10px;
          color: #fff;
          cursor: pointer;

          &:hover {
            opacity: .8;
          }
        }

        .button:nth-child(2) {
          background-color: #f7865c;
        }
      }
    }
  }
}
</style>