<template>
  <div class="scroll-container">
    <div class="content" ref="content">
      <slot></slot>
    </div>
    <div class="scrollbar" ref="scrollbar">
      <div class="thumb" ref="thumb"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomScrollbar',
  props: {
    scrollUpdate: {
      type: Function,
      required: true,
    }
  },
  data() {
    return {
      debounceTimer: null
    }
  },
  mounted() {
    this.updateThumb(false);
    this.$refs.content.addEventListener('scroll', this.updateThumb);
    this.$refs.thumb.addEventListener('mousedown', this.onMouseDown);
  },
  beforeDestroy() {
    this.$refs.content.removeEventListener('scroll', this.updateThumb);
    this.$refs.thumb.removeEventListener('mousedown', this.onMouseDown);
  },
  methods: {
    updateThumb(scroll = true) {

      if (scroll) { 
        clearTimeout(this.debounceTimer);
        this.debounceTimer = setTimeout(() => {
          this.scrollUpdate();
        }, 300);
      }


      const content = this.$refs.content;
      const thumb = this.$refs.thumb;
      const containerHeight = this.$el.clientHeight;
      const contentHeight = content.scrollHeight;
      const scrollRatio = containerHeight / contentHeight;
      const thumbHeight = Math.max(containerHeight * scrollRatio, 30); // Minimum thumb height
      const thumbTop = content.scrollTop * scrollRatio;

      thumb.style.height = `${thumbHeight}px`;
      thumb.style.transform = `translateY(${thumbTop}px)`;
    },
    onMouseDown(event) {
      const thumb = this.$refs.thumb;
      const startY = event.clientY;
      const startTop = parseFloat(thumb.style.transform.replace('translateY(', '').replace('px)', '')) || 0;

      const onMouseMove = (event) => {
        const deltaY = event.clientY - startY;
        const newTop = startTop + deltaY;
        const scrollRatio = this.$refs.content.scrollHeight / this.$el.clientHeight;
        this.$refs.content.scrollTop = newTop * scrollRatio;
        this.updateThumb();
      };

      const onMouseUp = () => {
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
      };

      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    },
  },
};
</script>

<style scoped>
.scroll-container {
  position: relative;
  /* width: 100%; */
  /* height: 400px; */
  background-color: #ffffff;
  /* border: 1px solid #ccc;
  border-radius: 8px; */
  overflow: hidden;
}

.content {
  height: 100%;
  overflow-y: scroll;
  /* padding: 10px; */
  box-sizing: border-box;
}

.scrollbar {
  position: absolute;
  right: 3px;
  top: 0;
  width: 12px;
  height: 99%;
  /* background: #f1f1f1; */
  background: #fff;
  padding: 1px;
  /* margin-right: 3px; */
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}

.thumb {
  position: relative;
  width: 100%;
  /* background: #888; */
  background: #d9d9d9;
  border-radius: 8px;
  cursor: pointer;
}

.thumb:hover {
  background: #555;
}
</style>
