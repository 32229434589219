<template>
  <div class="video-player">
    <video ref="videoPlayer" :muted="isMuted" :src="videoSrc" @ended="handleVideoEnd">
    </video>
  </div>
</template>

<script>
import openChest from '@game/assets/media/open-chest.mp4';

export default {
  name: 'OpenChest',
  // mounted() {
  //   this.$store.commit ('setCurrentAudio', {audioSrc: ''})
  //   this.$audioManager.stopAll()
  // },
  data() {
    return {
      videoSrc: openChest,
    }
  },
  computed: {
    isMuted() { return this.$store.state.audio.masterVolume === 0 }
  },
  methods: {
    play() {
      this.$refs.videoPlayer.play().catch(error => {
        console.error('play error:', error);
      })
    },
    handleVideoEnd() {
      this.$router.push({ name: 'Rewards' })
    },
  },
}
</script>

<style scoped>
.video-player {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>