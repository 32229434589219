<template>
  <div class="scene5-container">
    <AudioIcon />
    <div class="scene5-dialog">
      <p>{{ text }}</p>
      <div class="button" @click="onClick">{{ buttonText }}</div>
    </div>
  </div>
</template>

<script>
import { tLevel4 } from '@game/utils';
import { audioMixinLevel42 } from '@game/mixins/audioMixin.js';

export default {
  name: 'Scene5',
  mixins: [audioMixinLevel42],
  beforeCreate() {
    this.$store.commit('resetTask')
  },
  data() {
    return {
      text: tLevel4('scene5.text'),
      buttonText: tLevel4('scene5.buttonText'),
    }
  },
  methods: {
    onClick() {
      this.$audioManager.playButtonNext(() => {
        this.$router.push({ name: 'L4Scene6' })
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.scene5-container {
  /* animation */
  position: absolute;

  width: 100%;
  height: 100%;
  background-image: url(@game/Level04/assets/img/scene5-background.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  border: 1px solid red;

  .scene5-dialog {
    margin-bottom: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 30px;

    border: 2px solid #94afe8;
    border-radius: 1rem;
    background-color: #fff;
    opacity: .9;
    width: 240px;
    padding: 30px 30px;
    padding-bottom: 10px;

    p {
      margin: 0;
      text-align: justify;
    }

    .button {
      bottom: 6rem;
      background-color: #66a261;
      color: #fff;
      text-align: center;
      padding: 5px 10px;
      border-radius: .5rem;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
</style>