<template>
  <div class="game">
    <AudioIcon />
    <div class="header">
      <div class="timing-container">
        <div class="timing-bar">{{ secondsToHms(time) }}</div>
      </div>
      <div class="progress-bar">
        <div class="progress" :style="{ width: `${progress}%` }"></div>
      </div>
      <div class="level-bar">{{ title }}</div>
    </div>
    <div class="content">
      <ul>
        <li v-for="(cell, index) in cells" :key="index">
          <!-- <img :style="{visibility: cell.isShow ? 'visible' : 'hidden'}" :src="cell.path" alt="" @click="onSelect(index)" :class="cell.isSelected ? 'selected' : ''"> -->
          <img :style="{ opacity: cell.isShow ? 1 : 0 }" :src="cell.path" alt="" @click="onSelect(index, cell)"
            :class="imgClass(cell)">
        </li>
      </ul>
    </div>
    <div class="footer" :class="{ hidden: isGameDialog }">{{ footerText }}</div>
    <transition appear enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut">
      <GameDialog v-show="isGameDialog" :notifyParent="handleNotify" />
    </transition>
    <TimeoutDialog v-show="isTimeDialogShow" :restartGame="timeOutRestart" :continueGame="timeOutContinue" />
  </div>
</template>

<script>
import GameDialog from '@game/Level04/components/GameDialog.vue';
import TimeoutDialog from '@game/components/TimeoutDialog.vue';
import { tLevel4 } from '@game/utils';

import djvu from '@game/Level04/assets/img/djvu.png';
import locky from '@game/Level04/assets/img/locky.png';
import ransomware from '@game/Level04/assets/img/ransomware.png';
import wannacry from '@game/Level04/assets/img/wannacry.png';
import trojan from '@game/Level04/assets/img/trojan.png';

import selectIconSound from '@game/assets/media/level04/select-icon.mp3';
import killVirusSound from '@game/assets/media/level04/kill-virus.wav';

import virusShowSound from '@game/assets/media/level04/virus-show.wav';

import { audioMixinLevel41 } from '@game/mixins/audioMixin.js';

const timeDuration = 120;

function shuffleArray() {
  const originalArray = [{
    path: djvu,
    type: 0,
    isShow: true,
    isSelected: false,
  }, {
    path: locky,
    type: 1,
    isShow: true,
    isSelected: false,
  }, {
    path: ransomware,
    type: 2,
    isShow: true,
    isSelected: false,
  }, {
    path: wannacry,
    type: 3,
    isShow: true,
    isSelected: false,
  }, {
    path: trojan,
    type: 4,
    isShow: true,
    isSelected: false,
  },];
  const array = Array.from({ length: 6 }).flatMap(() => originalArray)
  array.sort(() => Math.random() - 0.5);
  return array;
}

export default {
  name: 'TileMatching',
  mixins: [audioMixinLevel41],
  components: {
    GameDialog,
    TimeoutDialog,
  },
  mounted() {
    this.startTimer()
  },
  beforeDestroy() {
    this.stopTimer()
  },
  data() {
    return {
      score: 100,
      progress: 0,
      time: timeDuration,
      timer: null,
      cells: shuffleArray(),
      isSuccess: false,
      title: tLevel4('scene4.title'),
      footerText: tLevel4('scene4.footerText'),
      isGameDialog: false,
      notified: false,
      isTimeDialogShow: false,
    }
  },
  computed: {
    avatarPosition() {
      if (this.progress === 0) {
        return '-2%';
      } else if (this.progress >= 85) {
        return `${this.progress - 10}%`
      } else {
        return `${this.progress - 2}%`
      }
    }
  },
  methods: {
    handleNotify() {
      this.notified = true;
      this.isGameDialog = false;
    },

    onSelect(index, cell) {
      if (!cell.isShow) return;

      this.$audioManager.play('other', selectIconSound);

      const selectedItems = this.cells.filter((item, i) => item.isSelected && i !== index)
      if (selectedItems.length >= 2) return;

      this.cells.splice(index, 1, {
        path: this.cells[index].path,
        isSelected: !this.cells[index].isSelected,
        isShow: this.cells[index].isShow,
        type: this.cells[index].type,
      })

      if (selectedItems.length === 1) {
        if (selectedItems[0].type === this.cells[index].type) {

          this.$audioManager.play('other', killVirusSound)

          this.cells = this.cells.map(item => {
            if (item.isSelected) {
              item.isShow = false;
              item.isSelected = false;
            }
            return item;
          })

          const count = this.cells.filter(item => !item.isShow).length
          if (!this.notified && count >= 6) {
            this.$audioManager.play('other', virusShowSound)
            this.isGameDialog = true
          }

          this.progress = 100 * count / 30;
          if (count === 30) {
            // 通关
            this.stopTimer();
            this.isSuccess = true;
            this.$store.commit('completeTask')
            this.$router.push({ name: 'L4Scene5' })
          }
        }
      }
    },

    imgClass(cell) {
      if (cell.isSelected) {
        return 'selected'
      } else if (!cell.isShow) {
        return 'disable-hover'
      } else {
        return ''
      }
    },

    timeOutRestart() {
      this.$audioManager.playButtonPrevious(() => {
        this.$router.push({ name: 'L4Scene0' })
      })
    },
    timeOutContinue() {
      this.$audioManager.playButtonNext(() => {
        this.$router.push({ name: 'L4Scene3' })
      })
    },

    startTimer() {
      if (this.timer === null) {
        this.timer = setInterval(() => {
          if (this.time <= 0) {
            this.stopTimer();
            this.isTimeDialogShow = true
            return
          }
          this.time--;
        }, 1000);
      }
    },

    stopTimer() {
      if (this.timer !== null) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },

    secondsToHms(d) {
      d = Number(d);
      // const h = Math.floor(d / 3600);
      const m = Math.floor(d % 3600 / 60);
      const s = Math.floor(d % 3600 % 60);
      return `${String(m).padStart(2, '0')} : ${String(s).padStart(2, '0')}`;
    },

    dlgReturn() {
      this.$router.push({ name: 'LevelSelection' })
    },

    dlgContinue() {
      this.progress = 0;
      this.time = timeDuration;
      this.isSuccess = false;
      this.cells = shuffleArray();
      this.cells.forEach(item => {
        item.isShow = true;
        item.isSelected = false
      })
      this.startTimer();
    }
  }
}
</script>

<style lang="scss" scoped>
.game {
  /* animation */
  position: absolute;

  width: 100%;
  height: 100%;
  background-image: url(@game/Level04/assets/img/game-background.jpg);
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;


  .header {
    box-sizing: border-box;
    width: 100%;
    padding: 0 30px;
    padding-top: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    .timing-container {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .timing-bar {
        box-sizing: border-box;
        width: 40%;
        height: 28px;
        border: 2px solid #fff;
        color: #50a458;
        background-color: #d9d9d9;
        border-radius: 12px;
        display: flex;
        align-items: center;
        padding-left: 10px;
        font-weight: bold;
        // box-shadow: 2px 5px #a8c2cb;
        position: relative;

        &::after {
          content: '';
          display: block;
          width: 35px;
          height: 35px;
          background-image: url(@game/assets/img/clock.png);
          background-size: contain;
          background-repeat: no-repeat;
          position: absolute;
          top: -10px;
          right: 5px;
        }
      }
    }

    .progress-bar {
      width: 100%;
      height: 25px;
      border: 2px solid #fff;
      background-color: #d9d9d9;
      border-radius: 12px;
      // box-shadow: 2px 5px #a8c2cb;
      position: relative;

      .progress {
        position: relative;
        width: 235px;
        height: 25px;
        border-radius: 12px;
        background-color: #66a261;
        z-index: 100;
        transition: all 1s ease-in;

        &::after {
          content: '';
          display: block;
          width: 45px;
          height: 45px;
          background-image: url(@game/assets/img/avatar.png);
          background-size: contain;
          background-repeat: no-repeat;
          position: absolute;
          top: -10px;
          right: -40px;
        }
      }

      &::after {
        z-index: 99;
        content: '';
        display: block;
        width: 35px;
        height: 35px;
        background-image: url(@game/assets/img/key.png);
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        top: -5px;
        right: 0;
      }
    }

    .level-bar {
      box-sizing: border-box;
      padding: 5px 30px;
      border: 2px solid #fff;
      border-radius: 2rem;
      background-color: #50a459;
      color: #fff;
      font-size: 1rem;
      font-weight: bold;
    }
  }

  .content {
    padding: 10px 20px;

    ul {
      padding: 0;
      padding-left: 10px;
      list-style: none;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      row-gap: 10px;

      li {
        flex: 0 0 18%;
        box-sizing: border-box;
        /* cursor: pointer; */

        img {
          width: 52px;
          transition: all 0.2s ease-in-out;
          box-sizing: border-box;

          &:hover {
            display: inline-block;
            cursor: pointer;
            border: solid 2px rgb(9, 255, 0);
            border-radius: 6px;
            transform: scale(1.1);
          }
        }
      }
    }
  }

  .footer {
    box-sizing: border-box;
    margin-bottom: 60px;
    padding: 40px 40px;
    background-color: rgba(215, 215, 215, .5);
    border: 2px solid #fff;
    border-radius: 20px;
    font-size: 1.2rem;
    color: #000;
    box-shadow: 0 3px #dcaba9;
  }
}



.disable-hover {
  pointer-events: none;
}


.selected {
  display: inline-block;
  cursor: pointer;
  border: solid 2px rgb(9, 255, 0);
  border-radius: 6px;
  transform: scale(1.1);
}

.hidden {
  visibility: hidden;
}

.centered-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>