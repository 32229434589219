<template>
  <div class="cover">
    <AudioIcon />
    <img class="avatar" src="./assets/img/options/avatar.png" alt="">
    <ul>
      <li v-for="(item, index) in options" :key="index">
        <div class="img-container" :class="{ 'no-hover': !item.enable }" @click="onSelect(index)">
          <img :src="item.img" alt="">
          <div class="text-overlay">{{ item.text }}</div>
        </div>
      </li>
    </ul>

  </div>
</template>

<script>
import img1 from './assets/img/options/1.png';
import enable2 from './assets/img/options/2-enable.png';
import disable2 from './assets/img/options/2-disable.png';
import enable3 from './assets/img/options/3-enable.png';
import disable3 from './assets/img/options/3-disable.png';
import enable4 from './assets/img/options/4-enable.png';
import disable4 from './assets/img/options/4-disable.png';
import enable5 from './assets/img/options/5-enable.png';
import disable5 from './assets/img/options/5-disable.png';

export default {
  name: 'LevelSelection',
  mounted() {
    // this.$audioManager.stop('bg')
    this.$store.commit('setCurrentAudio', { audioSrc: '' });
  },
  data() {
    return {
      options: [{
        img: img1,
        text: this.$t('miniGame.level1.scene0.name'),
        enable: true,
      }, {
        img: this.isLevelAvailable(2) ? enable2 : disable2,
        text: this.$t('miniGame.level2.scene0.name'),
        enable: this.isLevelAvailable(2),
      }, {
        img: this.isLevelAvailable(3) ? enable3 : disable3,
        text: this.$t('miniGame.level3.scene0.name'),
        enable: this.isLevelAvailable(3),
      }, {
        img: this.isLevelAvailable(4) ? enable4 : disable4,
        text: this.$t('miniGame.level4.scene0.name'),
        enable: this.isLevelAvailable(4),
      }, {
        img: this.isLevelAvailable(5) ? enable5 : disable5,
        text: this.$t('miniGame.level5.scene0.name'),
        enable: this.isLevelAvailable(5),
      },]
    }
  },
  methods: {
    isLevelAvailable(level) {
      return this.$store.state.availableLevels.includes(level)
    },
    onSelect(index) {
      this.$audioManager.playButtonNext(() => {
        switch (index) {
          case 0:
            this.$router.push({ name: 'L1Scene0' })
            break;
          case 1:
            this.$router.push({ name: 'L2Scene0' })
            break;
          case 2:
            this.$router.push({ name: 'L3Scene0' })
            break;
          case 3:
            this.$router.push({ name: 'L4Scene0' })
            break;
          case 4:
            this.$router.push({ name: 'L5Scene0' })
            break;
          default:
            break;
        }
      });
    }
  }
}
</script>

<style scoped>
.cover {
  /* animation */
  position: absolute;

  width: 100%;
  height: 100%;
  background-image: url(./assets/img/options/background.jpg);
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.avatar {
  position: absolute;
  top: 50px;
  width: 50px;
  height: 50px;
}


ul {
  list-style: none;
  padding: 0;
  margin-top: 100px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

ul li {
  /* margin-top: 2rem; */
  padding: 0;
}

.img-container {
  position: relative;
  cursor: pointer;
  display: inline-block;
}

.img-container img {
  display: block;
  width: 20rem;
}

.text-overlay {
  position: absolute;
  left: 23%;
  font-size: 1.5rem;
  color: #fff;
  /* letter-spacing: .15rem; */
}

ul li:nth-child(1) .text-overlay {
  top: 2.5rem;
}

ul li:nth-child(2) .text-overlay {
  top: 2.9rem;
  letter-spacing: normal;
}

ul li:nth-child(3) .text-overlay {
  top: 2.5rem;
}

ul li:nth-child(4) .text-overlay {
  top: 2.3rem;
}

ul li:nth-child(5) .text-overlay {
  top: 2.4rem;
}

.no-hover {
  pointer-events: none;
}
</style>
