<template>
  <div class="container">
    <AudioIcon />
    <div class="text-container">
      <p>{{ text }}</p>
      <img src="@game/assets/img/arrow.png" alt="" class="backward" @click="onBackward">
      <img src="@game/assets/img/arrow.png" alt="" class="forward" @click="onForward">
    </div>
  </div>
</template>

<script>
import { tLevel2 } from '@game/utils';
import { audioMixinLevel1 } from '@game/mixins/audioMixin.js';

export default {
  name: 'Scene2',
  mixins: [audioMixinLevel1],
  data() {
    return {
      text: tLevel2('scene6.text'),
    }
  },
  methods: {
    onBackward() {
      this.$audioManager.playButtonPrevious(() => {
        this.$router.go(-1)
      })
    },
    onForward() {
      this.$audioManager.playButtonNext(() => {
        this.$router.push({ name: 'L2Scene7' })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
    background-image: url(@game/Level02/assets/img/alex-1.jpg);

    .text-container {
        top: 590px;
        padding: 30px 40px;
    }
}
</style>