<template>
  <div class="container">
    <AudioIcon />
    <div class="container-text">
      <p>{{ text }}</p>
      <div class="button" @click="onForward">{{ buttonText }}</div>
    </div>
  </div>
</template>

<script>
import { tLevel3 } from '@game/utils';
import { audioMixinLevel1 } from '@game/mixins/audioMixin.js';

export default {
  name: 'Scene1',
  mixins: [audioMixinLevel1],
  data() {
    return {
      text: tLevel3('scene1.text'),
      buttonText: tLevel3('scene1.buttonText'),
    }
  },
  methods: {
    onForward() {
      this.$audioManager.playButtonNext(() => {
        this.$router.push({ name: 'L3Scene2' })
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.container {
  background-image: url(@game/assets/img/level03/scene-1-bg.jpg);

  .container-text {
    .button {
      margin-top: 20px;
    }
  }
}
</style>