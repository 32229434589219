<template>
    <div class="container">
        <AudioIcon />
        <div class="container-text">
            <p>{{ text }}</p>
            <img src="@game/assets/img/arrow.png" alt="" class="forward" @click="onForward">
            <img src="@game/assets/img/arrow.png" alt="" class="backward" @click="onBackward">
        </div>
    </div>
</template>

<script>
import { tLevel3 } from '@game/utils';
import { audioMixinLevel1 } from '@game/mixins/audioMixin.js';

export default {
    name: 'Scene12',
    mixins: [audioMixinLevel1],
    data() {
        return {
            text: tLevel3('scene16.text'),
        }
    },
    methods: {
        onForward() {
            this.$audioManager.playButtonNext(() => {
                this.$router.push({ name: 'L3Scene17' })
            })
        },
        onBackward() {
            this.$audioManager.playButtonPrevious(() => {
                this.$router.go(-1)
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    background-image: url(@game/assets/img/level03/scene-12-bg.jpg);
}
</style>