<template>
  <div class="cover">
    <AudioIcon />
    <div class="large-circle" :class="{ 'show-circle': isCircleShow }">
      <div class="wrapper" :class="{ 'show-text': isTextShow }">
        <p>{{ text[0] }}</p>
        <p>{{ text[1] }}</p>
        <p>{{ text[2] }}</p>
        <p>{{ text[3] }}</p>
        <p>{{ text[4] }}</p>
        <p>{{ text[5] }}</p>
      </div>
      <img class="forward" src="./assets/img/arrow.png" alt="" @click="onForward">
      <img class="backward" src="./assets/img/arrow.png" alt="" @click="onBackward">
    </div>
    <div class="video-player">
      <video ref="videoPlayer" autoplay muted :src="videoSrc" @play="handlePlay">
      </video>
    </div>
  </div>
</template>

<script>
import { audioMixinLevel1 } from './mixins/audioMixin.js';
import character from './assets/media/character.mp4';
import IamAlex from './assets/media/i-am-alex.wav';

export default {
  mixins: [audioMixinLevel1],
  name: 'GamePeople',
  beforeDestroy() {
    this.$audioManager.stop('IamAlex')
  },
  data() {
    return {
      videoSrc: character,
      text: this.$t('miniGame.characterProfile'),
      lineIndex: 0,
      charIndex: 0,
      typeText: '',
      isCircleShow: false,
      isTextShow: false,
    }
  },

  methods: {
    handlePlay() {
      setTimeout(() => {
        this.isCircleShow = true
        setTimeout(() => {
          this.isTextShow = true
          this.$audioManager.play('IamAlex', IamAlex)
        }, 1000);
      }, 1000);
    },
    onForward() {
      this.$audioManager.playButtonNext(() => {
        this.$router.push({ name: 'LevelSelection' })
      })
    },
    onBackward() {
      this.$audioManager.playButtonPrevious(() => {
        this.$router.go(-1)
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.cover {
  /* animation */
  position: absolute;

  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .large-circle {
    position: absolute;
    top: 70px;
    width: 260px;
    height: 260px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.5s ease;

    .wrapper {
      opacity: 0;
      transition: opacity 0.5s ease;

      p {
        margin: 0;
        line-height: 1.5rem;
        font-size: 16px;
        font-weight: normal;
        color: #417C6B;
        text-align: center;
      }
    }
    .wrapper.show-text {
      opacity: 1;
    }

    img {
      width: 35px;
      height: 25px;
      position: absolute;
      bottom: 30px;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }

    .forward {
      right: 15px;
    }

    .backward {
      left: 15px;
      transform: rotate(180deg);
    }

    &::before,
    &::after {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #fff;
      position: absolute;
      opacity: 0;
      transition: opacity 0.5s ease;
    }

    &::before {
      width: 40px;
      height: 40px;
      left: 20px;
      bottom: -40px;
    }

    &::after {
      left: 60px;
      bottom: -70px;
    }
  }

  .video-player {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }



  .large-circle.show-circle {
    opacity: 1;

    &::before,
    &::after {
      opacity: 1;
    }
  }
}
</style>
