<template>
    <div class="container">
        <AudioIcon />
        <div class="container-text">
            <p>{{ text }}</p>
            <div class="button" @click="onClick">{{ buttonText }}</div>
        </div>
    </div>
</template>

<script>
import { tLevel4 } from '@game/utils';
import { audioMixinLevel41 } from '@game/mixins/audioMixin.js';

export default {
    name: 'Scene2',
    mixins: [audioMixinLevel41],
    data() {
        return {
            text: tLevel4('scene3.alex'),
            buttonText: tLevel4('scene3.buttonText'),
        }
    },
    methods: {
        onClick() {
            this.$audioManager.playButtonNext(() => {
                this.$router.push({ name: 'L4Scene4' })
            })
        }
    }
}

</script>

<style lang="scss" scoped>
.container {
    background-image: url(@game/Level04/assets/img/alex.jpg);

    .container-text {
        gap: 10px;
        min-height: 180px;
    }
}

</style>