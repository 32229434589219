<template>
    <div class="virus-modal">
        <p>{{ text }}</p>
        <img class="img-virus" src="../assets/img/virus-2.png" alt="">
        <img class="img-forward" src="../../assets/img/arrow.png" alt="" @click="onClick">
    </div>
</template>

<script>
import { tLevel4 } from '../../utils';
export default {
    props: ['forward'],
    data() {
        return {
            text: tLevel4('scene4.virusText'),
        }
    },
    methods: {
        onClick() {
            this.forward();
        }
    },
}
</script>

<style lang="scss" scoped>
.virus-modal {
    box-sizing: border-box;
    width: 83%;
    /* height: 15%; */
    min-height: 120px;
    padding: 10px 30px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 70px;

    background-color: #fff;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    z-index: 1001;

    p {
        margin: 0;
    }

    img {
        position: absolute;
        width: 2.5rem;
    }

    .img-virus {
        width: 50%;
        top: -85%;
        left: -8%;
    }

    .img-forward {
        right: -0.5rem;
        bottom: -0.3rem;
        cursor: pointer;

        &:hover {
            opacity: .8;
        }
    }
}
</style>