import i18n from '../../lang';

export function tLevel1(key) {
  return i18n.t(`miniGame.level1.${key}`);
}

export function tLevel2(key) {
  return i18n.t(`miniGame.level2.${key}`);
}

export function tLevel3(key) {
  return i18n.t(`miniGame.level3.${key}`);
}

export function tLevel4(key) {
  return i18n.t(`miniGame.level4.${key}`);
}

export function tLevel5(key) {
  return i18n.t(`miniGame.level5.${key}`);
}

export function secondsToHms(d) {
  d = Number(d);
  // const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);
  return `${String(m).padStart(2, '0')} : ${String(s).padStart(2, '0')}`;
}
