<template>
  <div class="container">
    <AudioIcon />
    <QuestionnaireTemplate :time="remainTime" :score="score" :progress="progress" :title="title"
      :questionObj="questionObj" :dialogObj="dialogObj" />
    <TimeoutDialog v-show="isTimeDialogShow" :restartGame="timeOutRestart" :continueGame="timeOutContinue" />
  </div>
</template>

<script>
import { tLevel3 } from '@game/utils';
import QuestionnaireTemplate from '@game/Level03/components/QuestionnaireTemplate.vue';
import TimeoutDialog from '@game/components/TimeoutDialog.vue';
import { audioMixinLevel1 } from '@game/mixins/audioMixin.js';

export default {
  name: 'Scene3',
  mixins: [audioMixinLevel1],
  components: {
    QuestionnaireTemplate,
    TimeoutDialog,
  },
  mounted() {
    this.startTimer()
  },
  beforeDestroy() {
    this.stopTimer()
  },
  data() {
    return {
      timer: null,
      title: tLevel3('scene7.title'),
      questionObj: {
        callback: this.questionCallback,
        a: tLevel3('scene7.questionObj.a'),
        b: tLevel3('scene7.questionObj.b'),
        c: tLevel3('scene7.questionObj.c'),
      },
      dialogObj: {
        isShow: false,
        isCorrectAnswer: false,
        text: '',
        buttonText: tLevel3('scene3.dialogObj.buttonText'),
        forward: this.onForward,
      },
      isTimeDialogShow: false,
    }
  },
  computed: {
    remainTime() {
      return this.$store.state.level3.time
    },
    score() {
      return this.$store.state.level3.score
    },
    progress() {
      return this.$store.state.level3.progress
    }
  },
  methods: {
    questionCallback(selected) {
      if (selected === 'b') {
        this.dialogObj.text = tLevel3('scene7.dialogObj.textSuccess')
        this.dialogObj.isCorrectAnswer = true
      } else {
        this.dialogObj.text = tLevel3('scene7.dialogObj.textFail')
        this.dialogObj.isCorrectAnswer = false
      }
      this.dialogObj.isShow = true
      this.$store.dispatch('setProgress', 40)
    },
    onForward() {
      this.$store.commit('completeTask')
      this.$router.push({ name: 'L3Scene8' })
    },

    timeOutRestart() {
      this.$router.push({ name: 'L3Scene0' })
      this.$store.commit('resetTime')
    },
    timeOutContinue() {
      this.$router.push({ name: 'L3Scene6' })
      this.$store.commit('resetTime')
    },

    startTimer() {
      if (this.timer === null) {
        this.timer = setInterval(() => {
          if (this.remainTime <= 0) {
            this.isTimeDialogShow = true
            this.stopTimer();
            return
          }
          this.$store.dispatch('setTime', this.remainTime - 1)
        }, 1000);
      }
    },

    stopTimer() {
      if (this.timer !== null) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.container {
  /* animation */
  position: absolute;

  width: 100%;
  height: 100%;
}
</style>