export default {
  miniGame: {
    startGame: 'Start Game',
    characterProfile: [
      `Hi, I'm Alex. Calm strategy and resilient optimism,`,
      `I'm the tool to tackle every cybersecurity challenge with a positive mindset. No need to worry, all problems will be solved.`,
    ],
    firstLevel: 'Strongest passwords first',
    secondLevel: 'Think like a counterfeit',
    thirdLevel: 'Decision-making and disruption',
    fourthLevel: 'Eliminate threats',
    fifthLevel: 'Dangerous edge',
  },
};
