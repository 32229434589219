<template>
    <div class="container">
        <AudioIcon />
        <div class="container-text">
            <p>{{ text }}</p>
            <div class="button" @click="onClick">{{ buttonText }}</div>
        </div>
    </div>
</template>

<script>
import { tLevel1 } from '@game/utils';
import { audioMixinLevel1 } from '@game/mixins/audioMixin.js';
import startAnswer from '@game/assets/media/level01/start-answer.mp3';

export default {
    name: 'Scene8',
    mixins: [audioMixinLevel1],
    data() {
        return {
            text: tLevel1('scene8.text'),
            buttonText: tLevel1('scene8.buttonText'),
        }
    },
    methods: {
        onClick() {
            this.$audioManager.playButtonNext(() => {
                this.$router.push({ name: 'L1Scene9' })
            }, startAnswer)
        }
    }
}

</script>  

<style lang="scss" scoped>
.container {
    background-image: url(@game/Level01/assets/img/alex-shylock2.jpg);

    .container-text {
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        gap: 10px;
    }
}

</style>