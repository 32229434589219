<template>
    <div class="container">
        <AudioIcon />
        <h3 class="title">{{ title }}</h3>
        <div class="wrapper">
            <p>{{ text }}</p>
            <div class="button" @click="onForward">{{ buttonText }}</div>
        </div>
    </div>
</template>

<script>
import { tLevel3 } from '@game/utils';
import { audioMixinLevel1 } from '@game/mixins/audioMixin.js';

export default {
    name: 'Scene4',
    mixins: [audioMixinLevel1],
    beforeCreate() {
        this.$store.commit('resetTask')
    },
    data() {
        return {
            title: tLevel3('scene4.title'),
            text: tLevel3('scene4.text'),
            buttonText: tLevel3('scene4.buttonText'),
        }
    },
    methods: {
        onForward() {
            this.$audioManager.playButtonNext(() => {
                this.$router.push({ name: 'L3Scene5' })
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    /* animation */
    position: absolute;

    width: 100%;
    height: 100%;
    background-image: url(@game/assets/img/level03/scene-4-bg.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;

    .title {
        position: absolute;
        top: 30px;
        left: 50%;
        transform: translateX(-50%);
        border: 3px solid #fff;
        background-color: #66a261;
        color: #fff;
        border-radius: 20px;
        box-shadow: 2px 5px #a8c2cb;
        padding: 5px 20px;
    }

    .wrapper {
        width: 72%;
        position: absolute;
        top: 360px;
        bottom: 205px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        p {
            margin: 0;
            padding: 0 10px;
        }

        .button {
            background-color: #66a261;
            color: #fff;
            border-radius: 5px;
            padding: 2px 10px;
            cursor: pointer;

            &:hover {
                opacity: .8;
            }
        }
    }
}
</style>