<template>
    <div class="container">
        <AudioIcon />
        <div class="container-text">
            <div class="wrapper">
                <p>{{ text }}</p>
                <img class="virus" src="@game/Level04/assets/img/virus-1.png" alt="">
                <img class="forward" src="@game/assets/img/arrow.png" alt="" @click="onForward">
                <img class="backward" src="@game/assets/img/arrow.png" alt="" @click="onBackward">
            </div>
        </div>
    </div>
</template>

<script>
import { tLevel4 } from '@game/utils';
import { audioMixinLevel41 } from '@game/mixins/audioMixin.js';

export default {
    name: 'Scene2',
    mixins: [audioMixinLevel41],
    data() {
        return {
            text: tLevel4('scene2.virus')
        }
    },
    methods: {
        onForward() {
            this.$audioManager.playButtonNext(() => {
                this.$router.push({ name: 'L4Scene3' })
            })
        },
        onBackward() {
            this.$audioManager.playButtonPrevious(() => {
                this.$router.go(-1)
            })
        }
    }
}

</script>

<style lang="scss" scoped>
.container {
    background-image: url(@game/Level04/assets/img/virus-background.jpg);

    .container-text {
        min-height: 130px;
        .virus {
            position: absolute;
            top: -80%;
            left: -8%;
            width: 50%;
        }
    }
}


</style>