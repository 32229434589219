<script>
import Vue from 'vue'
import CustomScrollbar from '@game/Level02/components/CustomScrollbar.vue';
import Dialog from '@game/Level02/components/Dialog.vue';
import { tLevel2 } from '@game/utils';
import avatar from '@game/Level02/assets/img/avatar.png';
import plusDisable from '@game/Level02/assets/img/plus-disable.png';
import plusEnable from '@game/Level02/assets/img/plus-enable.png';
import scrollSound from '@game/assets/media/level02/scroll.mp3';
import selectMailSound from '@game/assets/media/level02/select-mail.mp3';
import selectErrorSound from '@game/assets/media/level01/select-error.wav';
import { audioMixinLevel1 } from '@game/mixins/audioMixin.js';

export default Vue.extend({
  name: 'Scene9',
  mixins: [audioMixinLevel1],
  components: {
    CustomScrollbar,
    Dialog,
  },
  data() {
    return {
      title: tLevel2('scene9.title'),
      cardTitle: tLevel2('scene4.cardTitle'),
      mailTitle: tLevel2('scene9.mailTitle'),
      mailSender: tLevel2('scene9.mailSender'),
      mailTitlePlus: plusDisable,
      mailTwoFactorPlus: plusDisable,
      mailAuthenticationPlus: plusDisable,
      mailOnlinePayPlus: plusDisable,
      mailText: tLevel2('scene9.mailText'),
      buttonText: tLevel2('scene4.buttonText'),
      isShowDialog: false,
    }
  },
  methods: {
    mailTitlePlusClick(event) {
      event.stopPropagation();

      this.mailTitlePlus = this.mailTitlePlus === plusDisable ? plusEnable : plusDisable
      // if (this.mailTitlePlus === plusEnable) {
      //   this.$audioManager.play('other', selectMailSound)
      // } else {
      //   this.$audioManager.play('other', selectErrorSound)
      // }
    },
    mailTwoFactorPlusClick(event) {
      event.stopPropagation();

      this.mailTwoFactorPlus = this.mailTwoFactorPlus === plusDisable ? plusEnable : plusDisable
      // if (this.mailTwoFactorPlus === plusEnable) {
      //   this.$audioManager.play('other', selectMailSound)
      // } else {
      //   this.$audioManager.play('other', selectErrorSound)
      // }
    },
    mailAuthenticationPlusClick(event) {
      event.stopPropagation();

      this.mailAuthenticationPlus = this.mailAuthenticationPlus === plusDisable ? plusEnable : plusDisable
      // if (this.mailAuthenticationPlus === plusEnable) {
      //   this.$audioManager.play('other', selectMailSound)
      // } else {
      //   this.$audioManager.play('other', selectErrorSound)
      // }
    },
    mailOnlinePayPlusClick(event) {
      event.stopPropagation();

      this.mailOnlinePayPlus = this.mailOnlinePayPlus === plusDisable ? plusEnable : plusDisable
      // if (this.mailOnlinePayPlus === plusEnable) {
      //   this.$audioManager.play('other', selectMailSound)
      // } else {
      //   this.$audioManager.play('other', selectErrorSound)
      // }
    },

    otherSelected() {
      // this.$audioManager.play('other', selectErrorSound)
    },

    onForward(event) {
      event.stopPropagation();

      this.$store.commit('completeTask')
      this.$audioManager.playButtonNext(() => {
        this.$router.push({ name: 'L2Scene10' })
      })
    },
    onSubmit(event) {
      event.stopPropagation();

      this.isShowDialog = true
      if (this.isAllSelected) {
        this.$audioManager.play('other', selectMailSound)
      } else {
        this.$audioManager.play('other', selectErrorSound)
      }
      // this.$audioManager.playButtonNext()
    },

    scrollUpdate() {
      this.$audioManager.play('other', scrollSound)
    }
  },
  computed: {
    isNoneSelected() {
      return this.mailTitlePlus === plusDisable && this.mailTwoFactorPlus === plusDisable && this.mailAuthenticationPlus === plusDisable && this.mailOnlinePayPlus === plusDisable;
    },
    isAllSelected() {
      return this.mailTitlePlus === plusEnable && this.mailTwoFactorPlus === plusEnable && this.mailAuthenticationPlus === plusEnable && this.mailOnlinePayPlus === plusEnable;
    }
  },
  render() {
    return (
      <div class="container" onClick={this.otherSelected}>
        <AudioIcon />
        <h3 class="title">{this.title}</h3>
        <div class="card">
          <div class="card-title">{this.cardTitle}</div>
          <div class="card-content">
            <div class="mail-head">
              <img class="avatar" src={avatar} alt="" />
              <div class="content">
                <div class="mail-title">{this.mailTitle}</div>
                <div class="mail-sender">
                  {this.mailSender}
                </div>
              </div>
            </div>
            <CustomScrollbar scrollUpdate={this.scrollUpdate}>
              <div class="mail-content">
                {this.mailText.map((item, index) => {
                  if (index === 0) {
                    return <p key={index} onClick={this.mailTitlePlusClick}>{item}<img class="plus" src={this.mailTitlePlus} /></p>
                  } else if (index === 2) {
                    return <p key={index} onClick={this.mailTwoFactorPlusClick}>{item[0]}<span>{item[1]}</span><img class="plus" src={this.mailTwoFactorPlus} /></p>
                  } else if (index === 4) {
                    return <p key={index} onClick={this.mailAuthenticationPlusClick}>
                      {item[0]}
                      <span>{item[1]}</span>
                      {item[2]}
                      <img class="plus" src={this.mailAuthenticationPlus} /></p>
                  } else if (index === 6) {
                    return <p><ul onClick={this.mailOnlinePayPlusClick}>
                      {item.map((v, k) => {
                        if (k === 3) {
                          return <li key={k}>{v} <img class="plus" src={this.mailOnlinePayPlus} /></li>
                        }
                        return <li key={k}>{v}</li>
                      })}
                    </ul></p>
                  }
                  return <p key={index}>{item}</p>
                })}
              </div>
            </CustomScrollbar>
            <div class={`button ${this.isNoneSelected ? 'button-disable' : ''}`} onClick={this.onSubmit}>{this.buttonText}</div>
          </div>
        </div>
        {this.isShowDialog ? <transition appear enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="animate__animated animate__fadeOut"><Dialog isAllSelected={this.isAllSelected} viewAnswer={this.onForward} /></transition> : null}
      </div>
    )
  }
})
</script>

<style lang="scss" rel='stylesheet/scss' scoped>
.container {
  /* animation */
  position: absolute;

  width: 100%;
  height: 100%;
  background-image: url(@game/Level02/assets/img/mail-bg.jpg);
  background-size: contain;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.title {
  width: 6rem;
  height: 2rem;
  background-color: #66a261;
  border: 3px solid #fff;
  border-radius: 1rem;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}

.card {
  width: 82%;
  min-height: 80%;
  max-height: 80%;
  border-radius: 1.5rem;
  background-color: #fff;
  overflow: hidden;
  // display: flex;
  // flex-direction: column;
  // justify-content: flex-start;
  // align-items: center;

  .plus {
    width: 1.2rem;
  }

  .card-title {
    background-color: #4a4a4a;
    height: 3rem;
    color: #d7cd81;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 2rem;
    text-align: center;
  }

  .card-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    padding-right: 5px;

    .mail-head {
      padding: 1rem 0;
      padding-left: 2.5rem;
      padding-bottom: 1.5rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;

      .avatar {
        width: 2.5rem;
      }

      .content {
        margin-left: .7rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .mail-title {
          font-size: .8rem;
          font-weight: bold;
          letter-spacing: 1px;
        }

        .mail-sender {
          font-size: .7rem;
          // color: #d9d9d9;
          position: relative;

          span {
            // color: #6466f6;
            cursor: pointer;
          }
        }

        .plus {
          right: -1.5rem;
          cursor: pointer;
        }
      }
    }

    .mail-content {
      height: 360px;
      // overflow-y: scroll;
      padding: 1rem 1rem;
      padding-top: 0;
      font-weight: bold;
      letter-spacing: 1px;

      p {
        font-size: .8rem;
        margin-top: 1rem;
        margin-left: .5rem;
      }

      p:first-child {
        margin-top: 0;
        // color: #f4b54e;
        cursor: pointer;
      }

      p:first-child img {
        vertical-align: bottom
      }

      p:nth-child(3) span {
        // color: #b82e48;
        cursor: pointer;
      }

      p:nth-child(3) img {
        vertical-align: bottom;
        cursor: pointer;
      }

      p:nth-child(5) span {
        // color: #60c3f4;
        cursor: pointer;
      }

      p:nth-child(5) img {
        vertical-align: bottom;
        cursor: pointer;
      }

      p:nth-child(8) {
        margin-bottom: 0;
      }

      p:nth-child(9) {
        margin-top: 0;
      }

      ul {
        margin: 0;
        padding: 0;
        padding-left: 10px;
        // color: #f4af43;
        cursor: pointer;
      }

      li img {
        vertical-align: bottom;
      }

    }

    .button {
      box-sizing: border-box;
      background-color: #50a459;
      padding: 5px 10px;
      border-radius: .5rem;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;

      cursor: pointer;
    }

    .button:hover {
      opacity: .8;
    }

    .button-disable {
      pointer-events: none;
      background-color: #d9d9d9;
      color: #000;
    }
  }
}
</style>