<template>
  <div class="container">
    <LevelClear :level="2" />
  </div>
</template>

<script>
import LevelClear from '@game/LevelClear';

export default {
  name: 'Scene14',
  components: {
    LevelClear,
  },
}
</script>

<style scoped>
.container {
  /* animation */
  position: absolute;

  width: 100%;
  height: 100%;
}
</style>