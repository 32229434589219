<template>
    <div>
        <transition-group appear name="animate__animated animate__bounce" tag="div" enter-active-class="animate__fadeIn"
            leave-active-class="animate__fadeOut">
            <div v-if="isShow" class="overlay" key="overlay"></div>
            <VirusDialog v-if="isShowVirusModal" key="virus-modal" :forward="virusForward" />
            <Shylock1Dialog v-if="isShowShylock1Modal" key="shylock1-modal" :backward="shylock1Backward"
                :forward="shylock1Forward" />
            <Alex1Dialog v-if="isShowAlex1Modal" key="alex1-modal" :backward="alex1Backward" :forward="alex1Forward" />
            <Shylock2Dialog v-if="isShowShylock2Modal" key="shylock2-modal" :backward="shylock2Backward"
                :forward="shylock2Forward" />
            <Alex2Dialog v-if="isShowAlex2Modal" key="alex2-modal" :forward="onContinue" />
        </transition-group>
    </div>
</template>

<script>
import 'animate.css';
import { tLevel4 } from '../../utils';
import VirusDialog from './VirusDialog.vue';
import Shylock1Dialog from './Shylock1Dialog.vue';
import Alex1Dialog from './Alex1Dialog.vue';
import Shylock2Dialog from './Shylock2Dialog.vue';
import Alex2Dialog from './Alex2Dialog.vue';


export default {
    name: 'GameDialog',
    props: ['notifyParent'],
    components: {
        VirusDialog,
        Shylock1Dialog,
        Alex1Dialog,
        Shylock2Dialog,
        Alex2Dialog
    },
    data() {
        return {
            isShowVirusModal: true,
            isShowShylock1Modal: false,
            isShowAlex1Modal: false,
            isShowShylock2Modal: false,
            isShowAlex2Modal: false,
            virusText: tLevel4('scene4.virusText'),
            shylock1: tLevel4('scene4.shylock1'),
            alex1: tLevel4('scene4.alex1'),
            shylock2: tLevel4('scene4.shylock2'),
            alex2: tLevel4('scene4.alex2'),
            buttonText: tLevel4('scene4.buttonText'),
        }
    },
    computed: {
        isShow() {
            return this.isShowVirusModal || this.isShowShylock1Modal || this.isShowAlex1Modal|| this.isShowShylock2Modal || this.isShowAlex2Modal;
        }
    },
    methods: {
        setAllHidden() {
            this.isShowVirusModal = false
            this.isShowShylock1Modal = false
            this.isShowAlex1Modal = false
            this.isShowShylock2Modal = false
            this.isShowAlex2Modal = false
        },
        virusForward() {
            this.$audioManager.playButtonNext()
            this.setAllHidden()
            this.isShowShylock1Modal = true
        },
        shylock1Forward() {
            this.$audioManager.playButtonNext()
            this.setAllHidden()
            this.isShowAlex1Modal = true
        },
        shylock1Backward() {
            this.$audioManager.playButtonPrevious()
            this.setAllHidden()
            this.isShowVirusModal = true
        },
        alex1Forward() {
            this.$audioManager.playButtonNext()
            this.setAllHidden()
            this.isShowShylock2Modal = true
        },
        alex1Backward() {
            this.$audioManager.playButtonPrevious()
            this.setAllHidden()
            this.isShowShylock1Modal = true
        },

        shylock2Forward() {
            this.$audioManager.playButtonNext()
            this.setAllHidden()
            this.isShowAlex2Modal = true
        },
        shylock2Backward() {
            this.$audioManager.playButtonPrevious()
            this.setAllHidden()
            this.isShowAlex1Modal = true
        },

        alex2Forward() {
            this.$audioManager.playButtonNext()
            this.setAllHidden()
            this.isShowShylock2Modal = true
        },
        alex2Backward() {
            this.$audioManager.playButtonPrevious()
            this.setAllHidden()
            this.isShowShylock2Modal = true
        },
        onContinue() {
            this.$audioManager.playButtonNext()
            this.setAllHidden()
            this.notifyParent()
        },        
    },
}
</script>

<style scoped>
.overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    z-index: 1000;   
}
</style>