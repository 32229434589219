<template>
    <div class="root-wrapper">
        <AudioIcon :bgLoop="false" />
        <div class="container" v-show="!isGameOver">
            <transition appear enter-active-class="animate__animated animate__fadeIn"
                @after-enter="onAnimationEnd('content-box')">
                <div class="content-box" v-show="isContentBoxShow">
                    <transition appear enter-active-class="animate__animated animate__fadeIn"
                        @after-enter="onAnimationEnd('gift-box-wrapper')">
                        <div class="gift-box-wrapper" v-show="isGiftBoxWrapper">
                            <div class="gift-box">
                                <transition appear enter-active-class="animate__animated animate__fadeInUp"
                                    @after-enter="onAnimationEnd('key-big')">
                                    <div class="key-big" :class="{ 'shake-y': isKeyBigShakeY }" v-show="isBigKeyShow">
                                    </div>
                                </transition>
                                <transition :name="starName" appear :enter-active-class="starEnterActiveClass"
                                    @after-enter="onAnimationEnd('star')">
                                    <div class="star" v-show="isStarShow"></div>
                                </transition>
                            </div>
                            <div class="oval"></div>
                        </div>
                    </transition>

                    <transition appear enter-active-class="animate__animated animate__fadeIn"
                        @after-enter="onAnimationEnd('keys-wrapper')">
                        <div class="keys-wrapper" v-show="isKeysWrapperShow">
                            <h1 class="title">{{ text }}</h1>
                            <div class="keys">
                                <transition appear enter-active-class="animate__animated animate__fadeIn"
                                    @after-enter="onKeysAnimationEnd(1)">
                                    <div class="key1" v-show="isShowKey1"></div>
                                </transition>

                                <transition appear enter-active-class="animate__animated animate__fadeIn"
                                    @after-enter="onKeysAnimationEnd(2)">
                                    <div class="key2" v-show="this.isShowKey2"></div>
                                </transition>

                                <transition appear enter-active-class="animate__animated animate__fadeIn"
                                    @after-enter="onKeysAnimationEnd(3)">
                                    <div class="key3" v-show="this.isShowKey3"></div>
                                </transition>
                                <transition appear enter-active-class="animate__animated animate__fadeIn"
                                    @after-enter="onKeysAnimationEnd(4)">
                                    <div class="key4" v-show="this.isShowKey4"></div>
                                </transition>
                                <transition appear enter-active-class="animate__animated animate__fadeIn">
                                    <div class="key5" v-show="this.isShowKey5"></div>
                                </transition>

                            </div>
                            <div class="button" :style="buttonStyle" @click="onClick">{{
                                buttonText }}
                            </div>
                        </div>
                    </transition>
                </div>
            </transition>
        </div>
        <OpenChest v-show="isGameOver" ref="openChest" />
    </div>
</template>

<script>

import OpenChest from '@game/GameOver/OpenChest.vue';

import levelClearSound from '@game/assets/media/level01/level-clear.wav';
import getKey from '@game/assets/media/level01/get-key.wav';

export default {
    name: 'LevelClear',
    components: {
        OpenChest,
    },
    props: {
        level: {
            type: Number,
            required: true,
        }
    },
    mounted() {
        setTimeout(() => {
            this.$store.commit('setCurrentAudio', { audioSrc: levelClearSound })
            this.isContentBoxShow = true
        }, 500);
    },
    beforeDestroy() {
        this.$audioManager.stopAll()
        this.$store.commit('setCurrentAudio', { audioSrc: '' })
    },
    data() {
        return {
            text: this.$t('miniGame.levelClear.text'),
            buttonText1: this.$t('miniGame.levelClear.buttonText1'),
            buttonText2: this.$t('miniGame.levelClear.buttonText2'),
            backgroundStyle: { backgroundImage: 'url(this.successKeys)' },
            isGameOver: false,
            isContentBoxShow: false,
            isGiftBoxWrapper: false,
            isBigKeyShow: false,
            isStarShow: false,
            isKeysWrapperShow: false,

            isKeyBigShakeY: false,
            starName: 'fadeIn',
            starEnterActiveClass: 'animate__animated animate__fadeIn',

            isShowKey1: false,
            isShowKey2: false,
            isShowKey3: false,
            isShowKey4: false,
            isShowKey5: false,
        }
    },
    computed: {
        buttonText() {
            if (this.level === 5) {
                return this.buttonText2
            }
            return this.buttonText1
        },
        buttonStyle() {
            if (this.level === 5) {
                return {
                    backgroundColor: '#f5c647'
                }
            } else {
                return {}
            }
        },
        successKeysClass() {
            return `key-background level${this.level}`
        }
    },
    methods: {
        onClick() {
            if (this.level === 5) {
                // this.$router.push('/game/game-over')
                this.$audioManager.playButtonNext(() => {
                    this.$store.commit('setCurrentAudio', { audioSrc: '' })
                    this.$audioManager.stopAll()
                    this.isGameOver = true
                    this.$refs.openChest.play()
                })
                return;
            }
            this.isGameOver = false
            this.$store.dispatch('addLevel', this.level + 1)
            this.$audioManager.playButtonNext(() => {
                this.$router.push({ name: 'LevelSelection' })
            })
        },
        onAnimationEnd(end) {
            switch (end) {
                case 'content-box':
                    this.isGiftBoxWrapper = true
                    break;
                case 'gift-box-wrapper':
                    this.isBigKeyShow = true
                    break;
                case 'key-big':
                    this.$audioManager.play('other', getKey)
                    this.isStarShow = true
                    this.isKeysWrapperShow = true
                    break;
                case 'star':
                    if (this.starName === 'flash') {
                        this.isKeyBigShakeY = true
                        return;
                    }
                    this.starName = 'flash'
                    this.starEnterActiveClass = 'animate__animated animate__flash animate__slow'
                    this.isStarShow = false;
                    setTimeout(() => {
                        this.isStarShow = true;
                    }, 0);
                    break;
                case 'keys-wrapper':
                    this.isShowKey1 = true
                    break;
            }
        },

        onKeysAnimationEnd(end) {
            switch (end) {
                case 1:
                    if (this.level > 1) this.isShowKey2 = true
                    break;
                case 2:
                    if (this.level > 2) this.isShowKey3 = true
                    break;
                case 3:
                    if (this.level > 3) this.isShowKey4 = true
                    break;
                case 4:
                    if (this.level > 4) this.isShowKey5 = true
                    break;
            }
        }
    }
}

</script>

<style lang="scss" scoped>
.root-wrapper {
    width: 100%;
    height: 100%;

    .container {
        width: 100%;
        height: 100%;

        background-image: url(@game/assets/img/success/background.jpg);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        .content-box {
            width: 260px;
            height: 260px;
            border-radius: 10%;
            background-color: #fffeff;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            gap: 15px;
            position: relative;


            .gift-box-wrapper {
                position: absolute;
                top: -105px;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 15px;

                .gift-box {
                    background-image: url(@game/assets/img/success/gift-box.png);
                    background-position: center;
                    background-size: contain;
                    background-repeat: no-repeat;
                    width: 128.5px;
                    height: 147.5px;

                    .star,
                    .key-big {
                        position: absolute;
                        background-position: center;
                        background-size: contain;
                        background-repeat: no-repeat;
                    }

                    .star {
                        background-image: url(@game/assets/img/success/star.png);
                        width: 100px;
                        height: 100px;
                        top: -20px;
                        left: 30px;
                    }

                    .key-big {
                        background-image: url(@game/assets/img/success/key-big.png);
                        width: 57px;
                        height: 60px;
                        top: -30px;
                        left: 55px;

                        // animation: keyBigMove 2s ease-in-out 1s infinite alternate;
                    }

                    .shake-y {
                        animation: keyBigMove 3s linear 1s infinite alternate;
                    }

                    @keyframes keyBigMove {

                        0%,
                        100% {}

                        50% {
                            transform: translateY(5px);
                        }
                    }
                }

                .oval {
                    width: 80px;
                    height: 30px;
                    background-color: #eae6e2;
                    border-radius: 50%;
                }
            }

            .keys-wrapper {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 15px;

                .title {
                    font-size: 1.8rem;
                    font-weight: normal;
                    white-space: nowrap;
                    color: #333a33;
                    font-family: 'hyt';
                    margin: 0;
                    padding: 0;
                }

                .keys {
                    background-image: url(@game/assets/img/success/keys.png);
                    background-position: center;
                    background-size: contain;
                    background-repeat: no-repeat;
                    width: calc(324px * 40 / 90);
                    height: 40px;
                    position: relative;

                    .key1,
                    .key2,
                    .key3,
                    .key4,
                    .key5 {
                        background-image: url(@game/assets/img/success/key-small.png);
                        background-position: center;
                        background-size: contain;
                        background-repeat: no-repeat;
                        width: calc(56px * 25.78 / 58);
                        height: 25.78px;
                        position: absolute;
                        left: 14px;
                        top: 7px;
                    }

                    .key2 {
                        left: 36px;
                    }

                    .key3 {
                        left: 58px;
                    }

                    .key4 {
                        left: 80px;
                    }

                    .key5 {
                        left: 102px;
                    }

                    .show-key {
                        opacity: 1;
                    }
                }

                .button {
                    padding: 5px 10px;
                    background-color: #66a261;
                    color: #f3f8f3;
                    border-radius: .3rem;
                    margin-bottom: 20px;
                    cursor: pointer;

                    &:hover {
                        opacity: 0.8;
                    }
                }
            }
        }
    }
}

</style>