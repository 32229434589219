import { render, staticRenderFns } from "./Scene6.vue?vue&type=template&id=af13f8ac&scoped=true"
import script from "./Scene6.vue?vue&type=script&lang=js"
export * from "./Scene6.vue?vue&type=script&lang=js"
import style0 from "./Scene6.vue?vue&type=style&index=0&id=af13f8ac&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af13f8ac",
  null
  
)

export default component.exports