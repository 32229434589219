import Vue from "vue";
import Router from "vue-router";
// import store from './store';
import GameContainer from "@game/GameContainer.vue";
import GameHome from "@game/GameHome";
import CharacterSelection from "@game/CharacterSelection";
import LevelSelection from "@game/LevelSelection.vue";
import CharacterProfile from "@game/CharacterProfile";
import LevelContainer from "@game/LevelContainer.vue";

Vue.use(Router);

// 动态加载指定关卡的所有组件
const contexts = {
  1: require.context(`@game/Level01`, false, /\.vue$/),
  2: require.context(`@game/Level02`, false, /\.vue$/),
  3: require.context(`@game/Level03`, false, /\.vue$/),
  4: require.context(`@game/Level04`, false, /\.vue$/),
  5: require.context(`@game/Level05`, false, /\.vue$/),
};
const loadLevelComponents = (level) => {
  const context = contexts[level];
  context.keys().forEach((key) => {
    const componentName = key.replace(/^\.\//, "").replace(/\.vue$/, "");
    // console.log(`L${level}${componentName}`);
    Vue.component(
      `L${level}${componentName}`,
      context(key).default || context(key)
    );
  });
};

const router = new Router({
  routes: [
    {
      path: "/",
      redirect: "/game",
    },
    {
      path: "/game",
      component: GameContainer,
      children: [
        {
          path: "",
          component: LevelContainer,
          children: [
            {
              path: "",
              name: "GameHome",
              component: GameHome,
            },
            {
              path: "character-selection",
              name: "CharacterSelection",
              component: CharacterSelection,
              meta: {
                mustFrom: [{ name: "GameHome" }, { name: "CharacterProfile" }],
              },
            },
            {
              path: "character-profile",
              name: "CharacterProfile",
              component: CharacterProfile,
              meta: {
                mustFrom: [
                  { name: "CharacterSelection" },
                  { name: "LevelSelection" },
                ],
              },
            },
            {
              path: "level-selection",
              name: "LevelSelection",
              component: LevelSelection,
              meta: {
                mustFrom: [
                  { name: "CharacterProfile" },
                  { name: "L1Scene0" },
                  { name: "L1Scene24" },
                  { name: "L2Scene14" },
                  { name: "L3Scene21" },
                  { name: "L4Scene9" },
                  { name: "L5Scene18" },
                ],
              },
            },
          ],
        },
        {
          path: "level-1",
          component: LevelContainer,
          beforeEnter: async (to, from, next) => {
            loadLevelComponents(1);
            next();
          },
          children: [
            {
              path: "",
              name: "L1Scene0",
              component: () => import("@game/Level01/Scene0.vue"),
              meta: {
                mustFrom: [{ name: "LevelSelection" }, { name: "L1Scene1" }],
              },
            },
            {
              path: "scene-1",
              name: "L1Scene1",
              component: () => import("@game/Level01/Scene1.vue"),
              meta: {
                mustFrom: [{ name: "L1Scene0" }, { name: "L1Scene2" }],
              },
            },
            {
              path: "scene-2",
              name: "L1Scene2",
              component: () => import("@game/Level01/Scene2.vue"),
              meta: {
                mustFrom: [{ name: "L1Scene1" }, { name: "L1Scene3" }],
              },
            },
            {
              path: "scene-3",
              name: "L1Scene3",
              component: () => import("@game/Level01/Scene3.vue"),
              meta: {
                mustFrom: [{ name: "L1Scene2" }, { name: "L1Scene4" }],
              },
            },
            {
              path: "scene-4",
              name: "L1Scene4",
              component: () => import("@game/Level01/Scene4.vue"),
              meta: {
                mustFrom: [{ name: "L1Scene3" }, { name: "L1Scene5" }],
              },
            },
            {
              path: "scene-5",
              name: "L1Scene5",
              component: () => import("@game/Level01/Scene5.vue"),
              meta: {
                mustFrom: [{ name: "L1Scene4" }, { name: "L1Scene6" }],
              },
            },
            {
              path: "scene-6",
              name: "L1Scene6",
              component: () => import("@game/Level01/Scene6.vue"),
              meta: {
                mustFrom: [{ name: "L1Scene5" }, { name: "L1Scene7" }],
              },
            },
            {
              path: "scene-7",
              name: "L1Scene7",
              component: () => import("@game/Level01/Scene7.vue"),
              meta: {
                mustFrom: [{ name: "L1Scene6" }, { name: "L1Scene8" }],
              },
            },
            {
              path: "scene-8",
              name: "L1Scene8",
              component: () => import("@game/Level01/Scene8.vue"),
              meta: {
                mustFrom: [{ name: "L1Scene7" }, { name: "L1Scene9" }],
              },
            },
            {
              path: "scene-9",
              name: "L1Scene9",
              component: () => import("@game/Level01/Scene9.vue"),
              meta: {
                mustFrom: [{ name: "L1Scene8" }, { name: "L1Scene10" }],
              },
            },
            {
              path: "scene-10",
              name: "L1Scene10",
              component: () => import("@game/Level01/Scene10.vue"),
              meta: {
                mustFrom: [
                  { name: "L1Scene9", checkTaskStatus: true },
                  { name: "L1Scene11" },
                ],
              },
            },
            {
              path: "scene-11",
              name: "L1Scene11",
              component: () => import("@game/Level01/Scene11.vue"),
              meta: {
                mustFrom: [{ name: "L1Scene10" }, { name: "L1Scene12" }],
              },
            },
            {
              path: "scene-12",
              name: "L1Scene12",
              component: () => import("@game/Level01/Scene12.vue"),
              meta: {
                mustFrom: [{ name: "L1Scene11" }, { name: "L1Scene13" }],
              },
            },
            {
              path: "scene-13",
              name: "L1Scene13",
              component: () => import("@game/Level01/Scene13.vue"),
              meta: {
                mustFrom: [{ name: "L1Scene12" }, { name: "L1Scene14" }],
              },
            },
            {
              path: "scene-14",
              name: "L1Scene14",
              component: () => import("@game/Level01/Scene14.vue"),
              meta: {
                mustFrom: [{ name: "L1Scene13" }, { name: "L1Scene15" }],
              },
            },
            {
              path: "scene-15",
              name: "L1Scene15",
              component: () => import("@game/Level01/Scene15.vue"),
              meta: {
                mustFrom: [{ name: "L1Scene14" }, { name: "L1Scene16" }],
              },
            },
            {
              path: "scene-16",
              name: "L1Scene16",
              component: () => import("@game/Level01/Scene16.vue"),
              meta: {
                mustFrom: [
                  { name: "L1Scene15", checkTaskStatus: true },
                  { name: "L1Scene17" },
                ],
              },
            },
            {
              path: "scene-17",
              name: "L1Scene17",
              component: () => import("@game/Level01/Scene17.vue"),
              meta: {
                mustFrom: [{ name: "L1Scene16" }, { name: "L1Scene18" }],
              },
            },
            {
              path: "scene-18",
              name: "L1Scene18",
              component: () => import("@game/Level01/Scene18.vue"),
              meta: {
                mustFrom: [{ name: "L1Scene17" }, { name: "L1Scene19" }],
              },
            },
            {
              path: "scene-19",
              name: "L1Scene19",
              component: () => import("@game/Level01/Scene19.vue"),
              meta: {
                mustFrom: [{ name: "L1Scene18" }, { name: "L1Scene20" }],
              },
            },
            {
              path: "scene-20",
              name: "L1Scene20",
              component: () => import("@game/Level01/Scene20.vue"),
              meta: {
                mustFrom: [{ name: "L1Scene19" }, { name: "L1Scene21" }],
              },
            },
            {
              path: "scene-21",
              name: "L1Scene21",
              component: () => import("@game/Level01/Scene21.vue"),
              meta: {
                mustFrom: [{ name: "L1Scene20" }, { name: "L1Scene22" }],
              },
            },
            {
              path: "scene-22",
              name: "L1Scene22",
              component: () => import("@game/Level01/Scene22.vue"),
              meta: {
                mustFrom: [
                  { name: "L1Scene21", checkTaskStatus: true },
                  { name: "L1Scene23" },
                ],
              },
            },
            {
              path: "scene-23",
              name: "L1Scene23",
              component: () => import("@game/Level01/Scene23.vue"),
              meta: {
                mustFrom: [{ name: "L1Scene22" }, { name: "L1Scene24" }],
              },
            },
            {
              path: "scene-24",
              name: "L1Scene24",
              component: () => import("@game/Level01/Scene24.vue"),
              meta: {
                mustFrom: [{ name: "L1Scene23" }, { name: "L2Scene0" }],
              },
            },
          ],
        },
        {
          path: "level-2",
          component: LevelContainer,
          beforeEnter: async (to, from, next) => {
            loadLevelComponents(2);
            next();
          },
          children: [
            {
              path: "",
              name: "L2Scene0",
              component: () => import("@game/Level02/Scene0.vue"),
              meta: {
                mustFrom: [{ name: "LevelSelection" }, { name: "L2Scene1" }],
              },
            },
            {
              path: "scene-1",
              name: "L2Scene1",
              component: () => import("@game/Level02/Scene1.vue"),
              meta: {
                mustFrom: [{ name: "L2Scene0" }, { name: "L2Scene2" }],
              },
            },
            {
              path: "scene-2",
              name: "L2Scene2",
              component: () => import("@game/Level02/Scene2.vue"),
              meta: {
                mustFrom: [{ name: "L2Scene1" }, { name: "L2Scene3" }],
              },
            },
            {
              path: "scene-3",
              name: "L2Scene3",
              component: () => import("@game/Level02/Scene3.vue"),
              meta: {
                mustFrom: [{ name: "L2Scene2" }, { name: "L2Scene4" }],
              },
            },
            {
              path: "scene-4",
              name: "L2Scene4",
              component: () => import("@game/Level02/Scene4"),
              meta: {
                mustFrom: [{ name: "L2Scene3" }, { name: "L2Scene5" }],
              },
            },
            {
              path: "scene-5",
              name: "L2Scene5",
              component: () => import("@game/Level02/Scene5"),
              meta: {
                mustFrom: [
                  { name: "L2Scene4", checkTaskStatus: true },
                  { name: "L2Scene6" },
                ],
              },
            },
            {
              path: "scene-6",
              name: "L2Scene6",
              component: () => import("@game/Level02/Scene6"),
              meta: {
                mustFrom: [{ name: "L2Scene5" }, { name: "L2Scene7" }],
              },
            },
            {
              path: "scene-7",
              name: "L2Scene7",
              component: () => import("@game/Level02/Scene7"),
              meta: {
                mustFrom: [{ name: "L2Scene6" }, { name: "L2Scene8" }],
              },
            },
            {
              path: "scene-8",
              name: "L2Scene8",
              component: () => import("@game/Level02/Scene8"),
              meta: {
                mustFrom: [{ name: "L2Scene7" }, { name: "L2Scene9" }],
              },
            },
            {
              path: "scene-9",
              name: "L2Scene9",
              component: () => import("@game/Level02/Scene9"),
              meta: {
                mustFrom: [{ name: "L2Scene8" }, { name: "L2Scene10" }],
              },
            },
            {
              path: "scene-10",
              name: "L2Scene10",
              component: () => import("@game/Level02/Scene10"),
              meta: {
                mustFrom: [
                  { name: "L2Scene9", checkTaskStatus: true },
                  { name: "L2Scene11" },
                ],
              },
            },
            {
              path: "scene-11",
              name: "L2Scene11",
              component: () => import("@game/Level02/Scene11"),
              meta: {
                mustFrom: [{ name: "L2Scene10" }, { name: "L2Scene12" }],
              },
            },
            {
              path: "scene-12",
              name: "L2Scene12",
              component: () => import("@game/Level02/Scene12"),
              meta: {
                mustFrom: [{ name: "L2Scene11" }, { name: "L2Scene13" }],
              },
            },
            {
              path: "scene-13",
              name: "L2Scene13",
              component: () => import("@game/Level02/Scene13"),
              meta: {
                mustFrom: [{ name: "L2Scene12" }, { name: "L2Scene14" }],
              },
            },
            {
              path: "scene-14",
              name: "L2Scene14",
              component: () => import("@game/Level02/Scene14"),
              meta: {
                mustFrom: [{ name: "L2Scene13" }, { name: "L3Scene0" }],
              },
            },
          ],
        },
        {
          path: "level-3",
          component: LevelContainer,
          beforeEnter: async (to, from, next) => {
            loadLevelComponents(3);
            next();
          },
          children: [
            {
              path: "",
              name: "L3Scene0",
              component: () => import("@game/Level03/Scene0.vue"),
              meta: {
                mustFrom: [
                  { name: "LevelSelection" },
                  { name: "L3Scene1" },
                  { name: "L3Scene3" },
                  { name: "L3Scene7" },
                  { name: "L3Scene10" },
                  { name: "L3Scene14" },
                  { name: "L3Scene18" },
                ],
              },
            },
            {
              path: "scene-1",
              name: "L3Scene1",
              component: () => import("@game/Level03/Scene1.vue"),
              meta: {
                mustFrom: [{ name: "L3Scene0" }, { name: "L3Scene2" }],
              },
            },
            {
              path: "scene-2",
              name: "L3Scene2",
              component: () => import("@game/Level03/Scene2.vue"),
              meta: {
                mustFrom: [{ name: "L3Scene1" }, { name: "L3Scene3" }],
              },
            },
            {
              path: "scene-3",
              name: "L3Scene3",
              component: () => import("@game/Level03/Scene3.vue"),
              meta: {
                mustFrom: [{ name: "L3Scene2" }, { name: "L3Scene4" }],
              },
            },
            {
              path: "scene-4",
              name: "L3Scene4",
              component: () => import("@game/Level03/Scene4.vue"),
              meta: {
                mustFrom: [
                  { name: "L3Scene3", checkTaskStatus: true },
                  { name: "L3Scene5" },
                ],
              },
            },
            {
              path: "scene-5",
              name: "L3Scene5",
              component: () => import("@game/Level03/Scene5.vue"),
              meta: {
                mustFrom: [{ name: "L3Scene4" }, { name: "L3Scene6" }],
              },
            },
            {
              path: "scene-6",
              name: "L3Scene6",
              component: () => import("@game/Level03/Scene6.vue"),
              meta: {
                mustFrom: [{ name: "L3Scene5" }, { name: "L3Scene7" }],
              },
            },
            {
              path: "scene-7",
              name: "L3Scene7",
              component: () => import("@game/Level03/Scene7.vue"),
              meta: {
                mustFrom: [{ name: "L3Scene6" }, { name: "L3Scene8" }],
              },
            },
            {
              path: "scene-8",
              name: "L3Scene8",
              component: () => import("@game/Level03/Scene8.vue"),
              meta: {
                mustFrom: [
                  { name: "L3Scene7", checkTaskStatus: true },
                  { name: "L3Scene9" },
                ],
              },
            },
            {
              path: "scene-9",
              name: "L3Scene9",
              component: () => import("@game/Level03/Scene9.vue"),
              meta: {
                mustFrom: [{ name: "L3Scene8" }, { name: "L3Scene10" }],
              },
            },
            {
              path: "scene-10",
              name: "L3Scene10",
              component: () => import("@game/Level03/Scene10.vue"),
              meta: {
                mustFrom: [{ name: "L3Scene9" }, { name: "L3Scene11" }],
              },
            },
            {
              path: "scene-11",
              name: "L3Scene11",
              component: () => import("@game/Level03/Scene11.vue"),
              meta: {
                mustFrom: [
                  { name: "L3Scene10", checkTaskStatus: true },
                  { name: "L3Scene12" },
                ],
              },
            },
            {
              path: "scene-12",
              name: "L3Scene12",
              component: () => import("@game/Level03/Scene12.vue"),
              meta: {
                mustFrom: [{ name: "L3Scene11" }, { name: "L3Scene13" }],
              },
            },
            {
              path: "scene-13",
              name: "L3Scene13",
              component: () => import("@game/Level03/Scene13.vue"),
              meta: {
                mustFrom: [{ name: "L3Scene12" }, { name: "L3Scene14" }],
              },
            },
            {
              path: "scene-14",
              name: "L3Scene14",
              component: () => import("@game/Level03/Scene14.vue"),
              meta: {
                mustFrom: [{ name: "L3Scene13" }, { name: "L3Scene15" }],
              },
            },
            {
              path: "scene-15",
              name: "L3Scene15",
              component: () => import("@game/Level03/Scene15.vue"),
              meta: {
                mustFrom: [
                  { name: "L3Scene14", checkTaskStatus: true },
                  { name: "L3Scene16" },
                ],
              },
            },
            {
              path: "scene-16",
              name: "L3Scene16",
              component: () => import("@game/Level03/Scene16.vue"),
              meta: {
                mustFrom: [{ name: "L3Scene15" }, { name: "L3Scene17" }],
              },
            },
            {
              path: "scene-17",
              name: "L3Scene17",
              component: () => import("@game/Level03/Scene17.vue"),
              meta: {
                mustFrom: [{ name: "L3Scene16" }, { name: "L3Scene18" }],
              },
            },
            {
              path: "scene-18",
              name: "L3Scene18",
              component: () => import("@game/Level03/Scene18.vue"),
              meta: {
                mustFrom: [{ name: "L3Scene17" }, { name: "L3Scene19" }],
              },
            },
            {
              path: "scene-19",
              name: "L3Scene19",
              component: () => import("@game/Level03/Scene19.vue"),
              meta: {
                mustFrom: [
                  { name: "L3Scene18", checkTaskStatus: true },
                  { name: "L3Scene20" },
                ],
              },
            },
            {
              path: "scene-20",
              name: "L3Scene20",
              component: () => import("@game/Level03/Scene20.vue"),
              meta: {
                mustFrom: [{ name: "L3Scene19" }, { name: "L3Scene21" }],
              },
            },
            {
              path: "scene-21",
              name: "L3Scene21",
              component: () => import("@game/Level03/Scene21.vue"),
              meta: {
                mustFrom: [{ name: "L3Scene20" }, { name: "L4Scene0" }],
              },
            },
          ],
        },
        {
          path: "level-4",
          component: LevelContainer,
          beforeEnter: async (to, from, next) => {
            loadLevelComponents(4);
            next();
          },
          children: [
            {
              path: "",
              name: "L4Scene0",
              component: () => import("@game/Level04/Scene0.vue"),
              meta: {
                mustFrom: [{ name: "LevelSelection" }, { name: "L4Scene1" }],
              },
            },
            {
              path: "scene-1",
              name: "L4Scene1",
              component: () => import("@game/Level04/Scene1.vue"),
              meta: {
                mustFrom: [{ name: "L4Scene0" }, { name: "L4Scene2" }],
              },
            },
            {
              path: "scene-2",
              name: "L4Scene2",
              component: () => import("@game/Level04/Scene2.vue"),
              meta: {
                mustFrom: [{ name: "L4Scene1" }, { name: "L4Scene3" }],
              },
            },
            {
              path: "scene-3",
              name: "L4Scene3",
              component: () => import("@game/Level04/Scene3.vue"),
              meta: {
                mustFrom: [{ name: "L4Scene2" }, { name: "L4Scene4" }],
              },
            },
            {
              path: "scene-4",
              name: "L4Scene4",
              component: () => import("@game/Level04/Scene4.vue"),
              meta: {
                mustFrom: [
                  { name: "L4Scene3" },
                  { name: "L4Scene5" },
                  { name: "L4Scene4" },
                ],
              },
            },
            {
              path: "scene-5",
              name: "L4Scene5",
              component: () => import("@game/Level04/Scene5.vue"),
              meta: {
                mustFrom: [
                  { name: "L4Scene4", checkTaskStatus: true },
                  { name: "L4Scene6" },
                ],
              },
            },
            {
              path: "scene-6",
              name: "L4Scene6",
              component: () => import("@game/Level04/Scene6.vue"),
              meta: {
                mustFrom: [{ name: "L4Scene5" }, { name: "L4Scene7" }],
              },
            },
            {
              path: "scene-7",
              name: "L4Scene7",
              component: () => import("@game/Level04/Scene7.vue"),
              meta: {
                mustFrom: [{ name: "L4Scene6" }, { name: "L4Scene8" }],
              },
            },
            {
              path: "scene-8",
              name: "L4Scene8",
              component: () => import("@game/Level04/Scene8.vue"),
              meta: {
                mustFrom: [{ name: "L4Scene7" }, { name: "L4Scene9" }],
              },
            },
            {
              path: "scene-9",
              name: "L4Scene9",
              component: () => import("@game/Level04/Scene9.vue"),
              meta: {
                mustFrom: [{ name: "L4Scene8" }, { name: "L5Scene0" }],
              },
            },
          ],
        },
        {
          path: "level-5",
          component: LevelContainer,
          beforeEnter: async (to, from, next) => {
            loadLevelComponents(5);
            next();
          },
          children: [
            {
              path: "",
              name: "L5Scene0",
              component: () => import("@game/Level05/Scene0.vue"),
              meta: {
                mustFrom: [{ name: "LevelSelection" }, { name: "L5Scene1" }],
              },
            },
            {
              path: "scene-1",
              name: "L5Scene1",
              component: () => import("@game/Level05/Scene1.vue"),
              meta: {
                mustFrom: [{ name: "L5Scene0" }, { name: "L5Scene2" }],
              },
            },
            {
              path: "scene-2",
              name: "L5Scene2",
              component: () => import("@game/Level05/Scene2.vue"),
              meta: {
                mustFrom: [{ name: "L5Scene1" }, { name: "L5Scene3" }],
              },
            },
            {
              path: "scene-3",
              name: "L5Scene3",
              component: () => import("@game/Level05/Scene3.vue"),
              meta: {
                mustFrom: [{ name: "L5Scene2" }, { name: "L5Scene4" }],
              },
            },
            {
              path: "scene-4",
              name: "L5Scene4",
              props: true,
              component: () => import("@game/Level05/Scene4.vue"),
              meta: {
                mustFrom: [{ name: "L5Scene3" }, { name: "L5Scene5" }],
              },
            },
            {
              path: "scene-5",
              name: "L5Scene5",
              component: () => import("@game/Level05/Scene5.vue"),
              meta: {
                mustFrom: [{ name: "L5Scene4" }, { name: "L5Scene6" }],
              },
            },
            {
              path: "scene-6",
              name: "L5Scene6",
              component: () => import("@game/Level05/Scene6.vue"),
              meta: {
                mustFrom: [{ name: "L5Scene5" }, { name: "L5Scene7" }],
              },
            },
            {
              path: "scene-7",
              name: "L5Scene7",
              component: () => import("@game/Level05/Scene7.vue"),
              meta: {
                mustFrom: [{ name: "L5Scene6" }, { name: "L5Scene8" }],
              },
            },
            {
              path: "scene-8",
              name: "L5Scene8",
              component: () => import("@game/Level05/Scene8.vue"),
              meta: {
                mustFrom: [{ name: "L5Scene7" }, { name: "L5Scene9" }],
              },
            },
            {
              path: "scene-9",
              name: "L5Scene9",
              props: true,
              component: () => import("@game/Level05/Scene9.vue"),
              meta: {
                mustFrom: [
                  { name: "L5Scene8" },
                  { name: "L5Scene10" },
                  { name: "L5Scene14" },
                  { name: "L5Scene15" },
                ],
              },
            },
            {
              path: "scene-10",
              name: "L5Scene10",
              component: () => import("@game/Level05/Scene10.vue"),
              meta: {
                mustFrom: [
                  { name: "L5Scene9", checkTaskStatus: true },
                  { name: "L5Scene11" },
                ],
              },
            },
            {
              path: "scene-11",
              name: "L5Scene11",
              component: () => import("@game/Level05/Scene11.vue"),
              meta: {
                mustFrom: [{ name: "L5Scene10" }, { name: "L5Scene12" }],
              },
            },
            {
              path: "scene-12",
              name: "L5Scene12",
              component: () => import("@game/Level05/Scene12.vue"),
              meta: {
                mustFrom: [{ name: "L5Scene11" }, { name: "L5Scene13" }],
              },
            },
            {
              path: "scene-13",
              name: "L5Scene13",
              component: () => import("@game/Level05/Scene13.vue"),
              meta: {
                mustFrom: [{ name: "L5Scene12" }, { name: "L5Scene14" }],
              },
            },
            {
              path: "scene-14",
              name: "L5Scene14",
              component: () => import("@game/Level05/Scene14.vue"),
              meta: {
                mustFrom: [{ name: "L5Scene13" }, { name: "L5Scene15" }],
              },
            },
            {
              path: "scene-15",
              name: "L5Scene15",
              component: () => import("@game/Level05/Scene15.vue"),
              meta: {
                mustFrom: [
                  { name: "L5Scene14" },
                  { name: "L5Scene16" },
                  { name: "L5Scene9", checkTaskStatus: true },
                ],
              },
            },
            {
              path: "scene-16",
              name: "L5Scene16",
              component: () => import("@game/Level05/Scene16.vue"),
              meta: {
                mustFrom: [{ name: "L5Scene15" }, { name: "L5Scene17" }],
              },
            },
            {
              path: "scene-17",
              name: "L5Scene17",
              component: () => import("@game/Level05/Scene17.vue"),
              meta: {
                mustFrom: [{ name: "L5Scene16" }, { name: "L5Scene18" }],
              },
            },
            {
              path: "scene-18",
              name: "L5Scene18",
              component: () => import("@game/Level05/Scene18.vue"),
              meta: {
                mustFrom: [{ name: "L5Scene17" }, { name: "Rewards" }],
              },
            },
          ],
        },
        {
          path: "game-over",
          component: LevelContainer,
          children: [
            {
              path: "",
              redirect: "rewards",
              // name: 'OpenChest',
              // component: () => import('./views/game/GameOver/OpenChest.vue'),
              // meta: {
              //   mustFrom: [{ name: 'L5Scene18' }, { name: 'Rewards' }],
              // },
            },
            {
              path: "rewards",
              name: "Rewards",
              component: () => import("@game/GameOver/Rewards.vue"),
              meta: {
                mustFrom: [{ name: "L5Scene18" }, { name: "Final" }],
              },
            },
            {
              path: "final",
              name: "Final",
              component: () => import("@game/GameOver/Final.vue"),
              meta: {
                mustFrom: [{ name: "Rewards" }],
              },
            },
          ],
        },
      ],
    },
    {
      path: "*",
      redirect: "/game",
    },
  ],
});

router.beforeEach((to, from, next) => {
  next();
  return;

  // console.log(to, from);

  // if (store.state.forceNavigate) {
  //   store.commit('setForceNavigate', false);
  //   next();
  // }

  // if (to.meta.mustFrom) {
  //   const mustFrom = to.meta.mustFrom.find((item) => item.name === from.name);
  //   if (!mustFrom) {
  //     console.log('forbidden');
  //     next(false);
  //     return;
  //   }

  //   if (mustFrom.checkTaskStatus && !store.state.taskCompleted) {
  //     // console.log('forbidden');
  //     next(false);
  //     return;
  //   }
  // }
  // store.commit('setCurrentScene', to.name);
  // next();
});

export default router;
