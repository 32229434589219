import Vue from 'vue';
import VueI18n from 'vue-i18n';

import zhLocale from './zh';
import esLocale from './es';

Vue.use(VueI18n)

const messages = {
    zh: {
        ...zhLocale
    },
    es: {
        ...esLocale
    },
    en: {

    },
}

const i18n = new VueI18n({
    locale: 'zh',
    messages,
})

export default i18n;