<template>
  <div class="container">
    <AudioIcon/>
    <LevelStart :title="title" :name="name" :forwardPath="forwardPath" />
  </div>
</template>

<script>
import { tLevel2 } from '@game/utils';
import LevelStart from '@game/LevelStart.vue';
import { audioMixinLevel1 } from '@game/mixins/audioMixin.js';

export default {
  name: 'Scene0',
  mixins: [audioMixinLevel1],
  components: {
    LevelStart
  },
  data() {
    return {
      title: tLevel2('scene0.title'),
      name: tLevel2('scene0.name'),
      forwardPath: '/game/level-2/scene-1',
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'LevelSelection') {
      this.$audioManager.stopAll();
    }
    next()
  },
}
</script>

<style scoped>
.container {
  /* animation */
  position: absolute;

  width: 100%;
  height: 100%;
}
</style>