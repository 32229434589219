<template>
  <div class="level-container">
    <transition appear enter-active-class="animate__animated animate__fadeInLeft"
      leave-active-class="animate__animated animate__fadeOutRight">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'LevelContainer',
}
</script>

<style scoped>
.level-container {
  width: 100%;
  height: 100%;
  font-family: 'OPlus';

  /* animation */
  position: absolute;
}

</style>