import Vue from 'vue';
// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import i18n from './lang';
import App from './App.vue';
import router from './router';
import store from './store';
import audioManager from './views/game/AudioManager';
import AudioIcon from './views/game/components/AudioIcon.vue';

import 'animate.css';
import './styles/animations.scss';
import './styles/font.scss';
import './styles/text-box.scss';

Vue.config.productionTip = false;

Vue.prototype.$audioManager = audioManager;

// Vue.use(ElementUI);

Vue.component('AudioIcon', AudioIcon)

new Vue({
  store,
  router,
  i18n,
  // beforeCreate() {
  //   const scene = this.$store.state.currentScene;
  //   if (scene) {
  //     this.$store.commit('setForceNavigate', true);
  //     this.$router.replace({ name: scene });
  //   }
  // },
  render: (h) => h(App),
}).$mount('#app');
