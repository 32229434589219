<template>
  <div class="container">
    <AudioIcon />
    <div class="text-container">
      <p>{{ text }}</p>
      <img src="@game/assets/img/arrow.png" alt="" class="backward" @click="onBackward">
      <img src="@game/assets/img/arrow.png" alt="" class="forward" @click="onForward">
    </div>
  </div>
</template>

<script>
import { tLevel2 } from '@game/utils';
import { audioMixinLevel1 } from '@game/mixins/audioMixin.js';

export default {
  name: 'Scene3',
  mixins: [audioMixinLevel1],
  data() {
    return {
      text: tLevel2('scene11.text'),
      buttonText: tLevel2('scene3.buttonText'),
    }
  },
  methods: {
    onBackward() {
      this.$audioManager.playButtonPrevious(() => {
        this.$router.go(-1)
      })
    },
    onForward() {
      this.$audioManager.playButtonNext(() => {
        this.$router.push({ name: 'L2Scene12' })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
    background-image: url(@game/Level02/assets/img/shylock-2.jpg);

    .text-container {
        top: 610px;
        padding: 40px 20px;

        p{
          text-align: center;
        }
    }
}

</style>