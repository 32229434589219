export default {
  miniGame: {
    startGame: '开始游戏',
    characterSelection: {
      title: '选择你的角色',
      alex: '员工艾利克斯',
      chunLong: '高管春龙总监',
    },
    characterProfile: [
      'H i，我是艾利克斯。',
      '冷静的策略和坚韧乐观态',
      '度，是我积极应对每一个',
      '网络安全挑战的利器。',
      '不用担心，',
      '问题都会解决的。',
    ],
    timeoutDialog: {
      text: '诶呀超时了!',
      buttonText1: '重新开始',
      buttonText2: '继续游戏',
    },
    levelClear: {
      text: '成功过关！',
      buttonText1: '下一关',
      buttonText2: '太棒了',
    },
    level1: {
      scene0: {
        title: '第一关',
        name: '最强密码优先',
      },
      scene1: {
        text: '这一天，你刚走进办公室。同事夏洛特慌慌张张跑来。',
      },
      scene2: {
        text: '“艾利克斯你终于来了！有件事你得帮帮我！我的电脑好像受到了攻击，所有的文件都不见了！”',
      },
      scene3: {
        text: '你迅速走到他办公桌前，拿起他的电脑，开始检查系统。',
      },
      scene4: {
        text: '“看来黑客利用了一个简单的密码爆破攻击。夏洛特，你的密码是不是用了个人信息？”',
      },
      scene5: {
        text: '“是的，我用了我的名字和生日。我以为这样容易记住。”',
      },
      scene6: {
        text: '“夏洛特，个人信息是很容易被猜到的，这可能是电脑被攻击的原因。我们需要立即更新你的密码，并且要确保它足够复杂，不容易被猜到。”',
      },
      scene7: {
        text: '“我记不住那么复杂的密码。请为我设计一个密码吧！”',
      },
      scene8: {
        text: '你打开密码设置的对话框，开始思考如何为夏洛特设计一个既安全又容易记忆的密码。',
        buttonText: '开始答题',
      },
      scene9: {
        shylock: '我记不住那么复杂的密码。请为我设计一个密码！',
        title: '更改密码',
        dialogTip: '密码不够强!',
        buttonTextConfirm: '确定',
        buttonTextView: '查看解析',
      },
      scene10: {
        title: '什么是弱口令?',
        description: `弱口令指的是那些容易被猜测、破解或暴力破解的密码。这些密码通常由简单的字符串组成，缺乏足够的复杂度和长度，从而使其在密码攻击中容易被攻破。
            一下是一些常见的弱口令示例：`,
        descriptionList: [
          '简单的数字组合：如“123456”。',
          '常见的单词或名字：如“password”。',
          '键盘上的连续字符：如“qwerty”。',
          '个人信息：如出生日期、电话号码等。',
        ],
        buttonText: '继续游戏',
      },
      scene11: {
        text: '记住密码的确重要，但安全性更加关键。',
      },
      scene12: {
        text: '试试用一个容易记住的句子，然后取每个单词的首字母，加上数字和特殊字符。',
      },
      scene13: {
        text: '我想用我最喜欢的乐队名和演唱会日期，一定既独特又好记。',
      },
      scene14: {
        text: '哇，听起来不错，是什么？',
      },
      scene15: {
        shylock: '这是秘密，但我可以告诉你它包含了字母和数字',
        buttonText: '提交',
        tooltip: '提示："Queen77Live"皇后乐队，1977年现场',
        dialogText: '密码设置错误',
        dialogButtonText: '返回',
      },
      scene16: {
        text: '太棒了！那你的生日密码该退休了',
      },
      scene17: {
        text: '或者我们可以做一些创意，比如shuieuYbuistv@!',
      },
      scene18: {
        text: '哇，这确实很强，但我不确定我能否记住。',
      },
      scene19: {
        text: [
          '这就是为什么我们使用',
          '密码管理器',
          '的原因。它可以生成并记住复杂的密码，你只需要记住管理器的主密码。',
        ],
      },
      scene20: {
        title: '如何设置强密码?',
        description: `弱口令指的是那些容易被猜测、破解或暴力破解的密码。这些密码通常由简单的字符串组成，缺乏足够的复杂度和长度，从而使其在密码攻击中容易被攻破。
            一下是一些常见的弱口令示例：`,
        descriptionList: [
          '使用强密码：包含大小写字母、数字和特殊符号，长度至少12位。',
          '不要使用容易猜到的信息，如生日、姓名或常见单词、词语。',
          '定期更换密码。',
          '不要在多个网站使用相同的密码。',
          '使用密码管理器来帮助记住复杂的密码。',
        ],
        buttonText: '复习一下',
      },
      scene21: {
        text: '试试用一个句子，比如“我最喜欢的电影是星际穿越，因为它让我思考宇宙的奥秘”，然后取每个单词的首字母，加上一些特殊符号：',
        buttonText: '提交',
        tooltip: '提示："Queen77Live"皇后乐队，1977年现场',
        dialogText: '密码设置错误',
        dialogButtonText: '返回',
      },
      scene22: {
        text: '哦，这样就容易多了！',
      },
      scene23: {
        text: '弱口令就像是没锁的门，攻击者一推就开。密码安全是一个持续性的过程。我们要保持警惕。',
      },
      scene24: {
        text: '成功过关！',
        buttonText: '下一关',
      },
    },
    level2: {
      scene0: {
        title: '第二关',
        name: '像冒牌货一样思考',
      },
      scene1: {
        text: '故事发生在一个风和日丽的早晨，本应是一个平静而美好的开始。然而，办公室里的气氛却异常紧张，同事们的谈话声中夹杂着一丝焦虑。',
      },
      scene2: {
        text: '你坐在办公桌前，电脑上的一封电子邮件显得格外刺眼，它的存在与周围的宁静形成了鲜明的对比。',
      },
      scene3: {
        text: [
          '邮件标题是',
          '“企业福利和带薪假期政策变化通知”',
          '，这个标题听起来很正式，但你的直觉告诉你，这可能是个陷阱。',
        ],
        buttonText: '查看邮件',
      },
      scene3bak: {
        shylock2: 'Hi，艾利克斯！快看看这封邮件，它会是钓鱼邮件吗？',
        buttonText: '查看邮件',
      },
      scene4: {
        title: '邮件一',
        cardTitle: '单击您认为可疑的邮件部分',
        mailTitle: '重要！关于企业员工福利的紧急通知',
        mailSender: ['service<', 'admin-mi@servcea.pw', '>'],
        mailText: [
          '各位同事：',
          '大家好!',
          `为了确保企业的福利方案和支持政策多方面满足人员的需求。请所有同事通过完成以下调查（包含五个问
           题），提供对今年福利政策尤其是带薪休假政策可能变化的反馈。`,
          '该调查将使我们更好地了解企业可以通过何种方式来改善福利服务。',
          '请通过下面的链接访问该调查。',
          'DFZZ Network Technology Co.,Ltd.带薪假调查 ',
          '在访问该门户时，只需要使用您的DFZZ Network Technology Co.企业凭证登陆即可开始。',
          '要求登陆仅是出于验证目的，并用于防止来自互联网的任何人完成该调查，所有回答均将匿名，且在提交时不会包括您的姓名或电子邮件。',
          // '烦请全体同事于2024年05月17日（周五）工作日结束前提交。',
          [
            '烦请全体同事于',
            '2024年05月17日 ',
            '（周五）',
            '工作日结束前提交。',
          ],
          '附件：《关于企业总部对于员工福利升级的调查》',
          '谢谢！',
          '人力资源部',
          '重要提示：如果您在垃圾邮件中收到此消息，则这是您的网络提供商造成的。请将此邮件移至您的收件箱并接照上述说明进行操作。',
        ],
        buttonText: '提交答案',
        dialog: {
          text1: ['差点就成功了！', '你找出了部分的假消息！'],
          text2: ['太棒了！', '你找出了所有假消息！'],
          buttonText: '查看解析',
        },
      },
      scene5: {
        title: '答案解析',
        answerList: [
          {
            title: '邮件发件人：',
            text: '常见的邮箱地址(servrce.pw)拼写错误。域名中的service”拼写成了“servrce。以及地址使用·pw域名，这种顶级域名（TLD）不常见，可能用于钓鱼或其他恶意活动。',
          },
          {
            title: '给出的链接：',
            text: '带薪假期是企业已经掌握的员工信息，并且不需要采用外部调查链接进行信息统计，也不需要重复输入用户名称等登陆信息。',
          },
          {
            title: '紧迫的时间：',
            text: '攻击者往往利用您的焦虑，催促目标人尽快完成操作，未写明具体原因的情况下，给出极短的完成时间，往往代表着包含危险动作。',
          },
          {
            title: '给出的附件：',
            text: '附件文档往往是风险的高发区域，要仔细观察附件文档是否名不副实，比如exe、dl等文件格式都意味着危险。',
          },
          {
            title: '邮箱的提示：',
            text: '识别垃圾邮件是我们的邮件系统提供的防护功能，能够有效过滤一些恶意邮件，并且不影响正常邮件的访问，特定的白名单风险可能会造成下一次的严重威。',
          },
        ],
        buttonText: '继续游戏',
      },
      scene6: {
        text: '你正在专心检查邮件，突然，你的脑海里闪过一个片段。',
      },
      scene7: {
        text: '一周前公司内部突然传起一个谣言，称有员工泄露了公司的商业机密。这个消息像病毒一样迅速蔓延，员工们互相猜疑，办公室的气氛变得紧张而异。这时，同事夏洛特抱着电脑神神秘秘的走过来。',
      },
      scene8: {
        text: '艾利克斯！快帮我看看这封邮件！我总觉得它很可疑！',
        buttonText: '查看邮件',
      },
      scene9: {
        title: '邮件二',
        mailTitle: '紧急！需要更新',
        mailSender: 'Support<admin-mi@Jsbank.info>',
        mailText: [
          '亲爱的会员夏洛特先生：',
          '请注意，从今天开始，我们将引入新的网上银行认证程序，以保障我们客户的信息。',
          [
            '作为这一新程序的一部分，',
            '我们将实施两步验证功能，防止对您账户的未授权访问。',
          ],
          '为了能够启用这些新功能，我们要求您通过点击下面的链接验证您的账户信息。',
          [
            '登录',
            ' www.bankcomm.chn/two-factor/authentication ',
            '开始这一过程。',
          ],
          '请注意，如果您不遵守这一要求，能会导致暂时无法访问我们的网上银行服务。',
          [
            '查看声明－选择停正接收纸质版声明，并可以在6年内在线查看您的声明。',
            '查看自动付款设置自动进行每月支付。',
            '余额转账-将余额转入您的信用卡账户。',
            '再次感谢您使用在线支付。',
          ],
          '此致，',
          '建设银行服务',
        ],
      },
      scene10: {
        answerList: [
          {
            title: '邮件的称呼：',
            text: '钓鱼邮件经常使用一般的问候语，如“亲爱的先生/女士”或“尊贵的客户”。合法的电子邮件通常以收件人的名字称呼。',
          },
          {
            title: '假冒的安抚、诱导性语言：',
            text: '互联网服务，尤其是金融服务机构，都会确保客户在自己的业务站点完成操作，而不会选择直接在邮件提供的链接中完成登录等特定操作。',
          },
          {
            title: '给出的登录地址：',
            text: '网站链接有很强的迷惑性，您可能认为自己登录了正确的网站，但其中的单词、排列、后缀等已经被攻击者篡改。',
          },
          {
            title: '模糊的操作说明：',
            text: '涉及用户账户的金融交易，并不会通过邮件显示详细的操作步骤和方法，关键步骤的引导性话语，要特别当心。',
          },
        ],
      },
      scene11: {
        text: '哇，这太狡猾了。 我差点就上当了。',
      },
      scene12: {
        text: '是的，这就是为什么我们需要提高警惕。',
      },
      scene13: {
        text: `随后，你协同安全部门的同事，迅速采取行动。
              随着调查的深入，你发现了几个关键的疑点：邮件中的链接指向了某个外部服务器，而且有几个同事的电脑在那段时间出现异常的网络活动。
              你意识到，这不仅仅是一起简单的钓鱼邮件时间，而是异常精心策划的网络攻击。`,
        buttonText: '结束游戏',
      },
    },
    level3: {
      scene0: {
        title: '第三关',
        name: '决策与干扰',
      },
      scene1: {
        text: '你像往常一样按时到达办公室。正准备打开电脑。这时，你的手机嗡得震动了一下收到了一条信息，是春龙总监发来的。信息的内容让你有些意外，是一份紧急项目的关键文件需要你立即发送。你解锁手机。',
        buttonText: '查看信息',
      },
      scene2: {
        text: [
          'Hi, 艾利克斯:',
          '昨天开会提到的最新版，并且包含所有必要的附件和注释的《xxx项目最终执行方案》文件请即刻发送至我的邮箱。我需要里面的关键数据信息以便我能迅速回复客户。所以请立即处理。',
          '感谢配合',
          '春龙总监',
        ],
        buttonText: '开始答题',
      },
      scene3: {
        title: '看到这个请求，应该怎么做好呢？',
        questionObj: {
          a: 'A. 立即发送文件，因为管理层的请求很紧急。',
          b: 'B. 先检查信息来源，确认是否与春龙总监的联系方式一致。并使用公司内部通讯录联系春龙总监确认请求。',
          c: 'C. 忽略这封信息，继续工作。',
        },
        dialogObj: {
          textFail: '糟糕，上当了!',
          textSuccess: '太棒了！你成功避免了一次潜在的关键数据泄露！',
          buttonText: '查看解析',
        },
      },
      scene4: {
        title: '答案解析：B',
        text: '在处理紧急请求时，验证请求者的身份至关重要。钓鱼攻击和社交工程是常见的手段，攻击者会假装成管理层或合作伙伴来诱使员工泄露敏感信息。',
        buttonText: '继续游戏',
      },
      scene5: {
        text: '他的语气有些急迫，但你注意到，这与春龙总监平时的风格不太一致。出于职业的敏感性，你决定先确认这条信息的真实性。因为通常紧急项目都会有相应的内部通知或者邮件确认。',
      },
      scene6: {
        text: '于是你打开公司内部通讯录，试图联系春龙总监，却发现并没有这个请求的记录。这让你感到困惑。你决定采取以下步骤来处理这个情况：',
      },
      scene7: {
        title: '你应该如何处理这个情况？',
        questionObj: {
          a: 'A. 立即回复假冒信息，告诉发件人你已经识破了他们的诡计。',
          b: 'B. 将此短信事件告知公司，与安全部门同事一起进行安全审查。',
          c: 'C. 认为既然自己没有上当，就没有必要再采取任何行动。',
        },
        dialogObj: {
          textFail: '警惕！判断错误！',
          textSuccess: '太棒了！你做出了正确的判断！',
        },
      },
      scene8: {
        title: '答案解析：B',
        text: '通过通知公司并进行安全审查不仅可以帮助防止个人成为诈骗的受害者，还可以提高整个组织的安全防护能力，有助于防止未来的攻击。同时，这也有助于构建一个更加警觉和安全的工作环境。',
      },
      scene9: {
        text: '这时，夏洛特急忙跑来，说一家合作伙伴公司的代表在会议结束时请求一份产品内部信息和联系人做参考。',
      },
      scene10: {
        title: '你应该如何处理这个请求？',
        questionObj: {
          a: 'A. 同意请求，因为伙伴公司是可信赖的。',
          b: 'B. 直接拒绝请求，因为这些信息属于公司内部资料。',
          c: 'C. 向公司请示，获得批准后再提供资料。',
        },
        dialogObj: {
          textFail: '选择错误！',
          textSuccess: '太棒了！你做出了明智的决策！',
        },
      },
      scene11: {
        title: '答案解析：C',
        text: '在提供任何公司内部资料之前，向公司请示并获得批准是最佳实践。这确保了任何信息共享都符合公司政策、法律法规要求，同时保持与伙伴公司的良好关系。在共享任何内部资料之前，必须获得适当的授权，并确保信息已被适当地去标识化或脱敏。这也有助于记录决策过程。',
      },
      scene12: {
        text: [
          '你向夏洛特解释到：在处理此类请求时，我们还应考虑以下因素：',
          '确保信息传输的安全，使用加密和安全的通信渠道。',
          '记录所有请求和批准过程，以备后续审计或合规性检查。',
        ],
      },
      scene13: {
        text: '“我最近在家中使用个人电脑工作时，有时会需要访问一些公司的内部信息。思考了几种方法，但都存在风险。那我应该如何安全地访问这些信息？”',
      },
      scene14: {
        title: '如何安全访问公司的内部信息？',
        questionObj: {
          a: 'A. 通过公司的远程桌面解决方案访问。',
          b: 'B. 将信息复制到个人硬盘上，方便随时使用。',
          c: 'C. 使用私人云存储服务同步数据。',
        },
        dialogObj: {
          textFail: '注意！行为有误！',
          textSuccess: '太棒了！你的选择体现了你的洞察力！',
        },
      },
      scene15: {
        title: '答案解析：A',
        text: '使用公司提供的远程桌面解决方案可以确保数据传输过程中的加密和安全。将公司信息复制到个人存储设备或使用不安全的云服务可能会使信息面临未授权访问的风险。',
      },
      scene16: {
        text: '你提醒他说，在公司的安全培训中提到，所有敏感数据的访问都应该通过安全的渠道。通过公司的远程桌面解决方案访问了内部信息，确保了数据的安全性。',
      },
      scene17: {
        text: '“如果我在家办公时，有紧急事务需要处理一些紧急的公司文件，但远程桌面短时间内无法使用，应该采取哪种方式处理文件？”',
      },
      scene18: {
        title: '应该采取哪种方式处理文件？',
        questionObj: {
          a: 'A. 通过私人邮箱将文件发送到个人电脑。',
          b: 'B. 使用公司备用的安全文件传输服务，如VPN或安全的云存储服务。',
          c: 'C. 用手机拍下文件照片，通过即时通讯软件发送给自己。',
        },
        dialogObj: {
          textFail: '做法不正确！',
          textSuccess: '太棒了！你再一次保证了数据的安全性！',
        },
      },
      scene19: {
        title: '答案解析:B',
        text: '即使在紧急情况下，也应避免使用非加密的通信渠道发送敏感信息。如果必须通过电子邮件或其他方式传输文件，确保使用公司提供的加密工具或服务对文件进行加密。VPN和加密技术可以提供必要的安全层，以保护数据在传输过程中不被截获和解读。',
      },
      scene20: {
        text: '数据安全不仅仅是技术问题，它还涉及到很多个方面，需要全员的参与，同时也是保护敏感信息、合法合规、维护信任、保障业务连续性和竞争力的关键，对组织的长期成功和可持续发展至关重要。',
        buttonText: '结束游戏',
      },
    },
    level4: {
      scene0: {
        title: '第四关',
        name: '消除威胁',
      },
      scene1: {
        shylock: '“系统被勒索软件攻击了!艾利克斯我们得赶紧行动!”',
      },
      scene2: {
        virus: '“哈哈哈,你们的数据现在归我所有了！想要拿回去?拿赎金来换！”',
      },
      scene3: {
        alex: '“赎金？我有更好的办法来消灭你！防火墙启动,防御加固!杀毒软件出动，病毒无处藏身！”',
        buttonText: '开始游戏',
      },
      scene4: {
        title: 'Level 04',

        virusText: '“呃啊，这是什么？我的勒索链接怎么被阻断了？”',
        shylock1: '“艾利克斯，病毒升级了！”',
        alex1: '“别担心，启动数据恢复，确保文件安全。”',

        shylock2: '“数据恢复了，系统重置中。”',
        alex2: '“继续加强网络安全，防止未来攻击。”',

        buttonText: '继续游戏',

        footerText: '请和夏洛特一起消灭这些病毒!',
      },
      scene5: {
        text: '太棒了！你成功地清楚了所有的勒索病毒，系统现已恢复至安全状态！',
        buttonText: '查看解析',
      },
      scene6: {
        title: '知识科普',
        blackmailText:
          '勒索软件是一种恶意软件，它会锁定受害者的数据或设备，并威胁受害者，使其保持锁定状态（或更糟糕的状态），并要求受害者向攻击者支付赎金。',
        lockyTitle: '病毒 Locky:',
        lockyText:
          '这是一种加密勒索软件，它通过电子邮件附件中的宏传播。一旦被感染，会导致设备无法访问被加密的数据。例如好莱坞长老会医疗中心支付了17000美元赎金。全球总损失难以精确估计。',
        buttonText: '下一页',
      },
      scene7: {
        wcryTitle: '病毒 WannaCry:',
        wcryText:
          '这是第一个引人注目的加密蠕虫病毒，利用NSA的EternalBlue漏洞在 网络中传播的勒索病毒。并还威胁受害者：如果7天内未收到赎金，就会擦除文件。它仍然是迄今为止最大的勒索软件攻击之一，估计损失高达40亿美元。',
        ryukTitle: '病毒 Ryuk:',
        ryukText:
          '这是一种针对企业网络的勒索软件，通常通过网络漏洞或者是钓鱼邮件传播，涉及大额的赎金要求。',
      },
      scene8: {
        troyTitle: '木马或特洛伊木马:',
        troyText:
          '它通常伪装成合法软件，诱骗用户下载。一旦中毒，它会删除、修改或捕获数据，控制设备。导致设备数据丢失、隐私泄露等巨大损失。',
        djvuTitle: '病毒 STOP/DJVU:',
        djvuText:
          '这是一种文件加密木马恶意软件，通过伪装成软件破解工具或捆绑在激活软件进行传播。它会秘密侵入计算机并通过加密来阻止所有文件。给普通用户和中小企业带来难以估计的灾难。',
        buttonText: '结束游戏',
      },
    },
    level5: {
      headTitle: '内部管理税务事宜对接群',
      members: {
        charles: '高层管理查尔斯',
        frank: '财务总监富兰克',
        girami: '税务局官员吉拉米',
        a: '同事A',
        b: '同事B',
        c: '同事C',
      },
      scene0: {
        title: '第五关',
        name: '危险边缘',
      },
      scene1: {
        text: '你发现电脑上多了一个远程控制的软件图标，你感到困惑。正当你准备着手解决这个突如其来的问题时，屏幕的角落突然弹出了一个新的消息提示。',
        buttonText: '查看消息',
      },
      scene2: {
        title: '重要！',
        text: '“紧急通知：税务局对我司展开稽查工作，为维护公司及人员的财产安全及声誉，避免不必要法律诉讼，请各部门相关人员配合处理。”',
        buttonText: '下一步',
      },
      scene3: {
        title: '群组成员',
        text: '紧接着，你被加入了一个名为“XX内部管理税务事宜对接群”的群聊，群里的成员包括你的同事ABC，高层管理查尔斯，财务总监富兰克以及一个自称是来自税务局的官员吉拉米。',
        buttonText: '加入对话',
      },
      scene4: {
        charlesText: '@所有人  税务相关事宜之后都在这里群里对接。',
        aText: '收到',
        bText: '收到',
        cText: '好的，我这边会及时跟进。',
      },
      scene5: {
        giramiText1:
          '我是税务局稽查部门工作人员吉拉米，贵公司于2024年2月15日被列入税务稽查名单。现查明贵司有一笔税款逾期未缴。请在下周四2024年3月15日前登陆https:// eitax.chinatax.gov.cn上传资料或根据给出文件提供材料。届时会有专管员到贵公司展开详细稽查工作。如逾期未进行处理，或将面临重罚。',
        giramiText2: ['公众号', '税务局抽查名单'],
      },
      scene6: {
        giramiText1: '廉政告知书.pdf',
        giramiText2: '税务稽查告知书.docx',
        giramiText3: '税务稽查相关材料.docx',
        giramiText4:
          '@查尔斯 @财务经理 请详细阅读以上材料，并在《送达回证》上签字盖章。请贵司积极配合并及时处理。',
      },
      scene7: {
        frankText1: '收到，我立刻处理。',
        frankText2: [
          '@所有人 【2024年3月税务局抽查企业和人员名单】请大家按照操作进行。',
          'https://eitax.chinatax.gov.cn',
        ],
        aText: '收到',
        bText: '了解，税务问题不容忽视，我会全力配合。',
        cText: '好的',
      },
      scene8: {
        frankText1: '已在处理',
        charlesText:
          '@财务总监富兰克 查询公司账户余额，按流程审批安排过去，安排好单子发这个群里给我',
        frankText2: '收到',
      },
      scene9: {
        bText1: '已在积极跟进中！ ',
        bText2: '已提交',
        frankText: '@所有人 请大家尽快处理',
        aText: '已完成',
        cText: '已提交',
        dialog: {
          a: 'A. 文件看起来很官方，同事们也弄好了，我应该立即配合。',
          b: 'B. 尽管群里讨论很激烈，但还是决定先不急于行动，即刻向公司确认这个通知的来源。',
        },
      },
      scene10: {
        text: '你点开了群里给的链接，并准备按照要求上传资料。网站要求你输入公司的一些敏感信息，包括税务识别号和个人信息及银行账户信息。',
      },
      scene11: {
        text: '正当你准备输入时，电脑屏幕突然变黑，防火墙弹出告警阻止信息：“您要访问的是诈骗网站，攻击者企图通过该网页窃取或删除您的个人敏感信息，请不要输入任何信息!”',
      },
      scene12: {
        text: '随后，IT部门立即前来向你解释到：“我们检测到这个网站已被标记为典型的税务诈骗网站。为保护你的安全，我们已采取措施封锁了这个链接，并将对公司网络进行进一步的安全审查。”',
      },
      scene13: {
        text: '此时，你逐渐从震惊中缓过神来，意识到自己刚才差点落入骗局。随即按照指示操作，避免了潜在的损失。',
      },
      scene14: {
        text: [
          '虽然你差点被欺骗，但幸运的是，你的行动及时得到了纠正，从而避免了不可估量的损失。期望这一次经历能让你意识到，即使在看似合理的情况下，保持警惕和验证信息的重要性。即使在压力下，也应该坚持使用正确的流程和验证步骤。让我们',
          '【回到最后选择画面】',
          '，去做出明智的选择吧！',
        ],
      },
      scene15: {
        text: '你决定不轻信群里的信息，而是联系了你的直接上级。他们表示对此事一无所知，并迅速联系了真正的财务部门。经过核实，确认了这是一个诈骗行为。',
      },
      scene16: {
        text: '紧接着，公司发出警告文件通知：“大家注意，我们收到了关于税务稽查的可疑通知。经过核实，税务局并未发出此类通知。请不要点击任何链接或提供任何信息。”',
      },
      scene17: {
        text: '太棒了！你的警觉性和正确行动帮助自己和公司避免了潜在的损失。',
        buttonText: '结束游戏',
      },
    },
    gameOver: {
      rewards: {
        title: '恭喜你',
        info: '成为数字世界的守护者！',
        text: '在信息安全领域，我们的每一个决策和点击都至关重要，它们共同构建一个坚不可摧的网络防线。愿你以智慧和冷静，不断解锁信息安全知识的宝箱，战胜每一次挑战。',
        buttonText: '领取勋章',
      },
      final: {
        title: '网安懂王',
        buttonText1: '下载勋章',
        buttonText2: '结束游戏',
      },
    },
  },
};
