// export default class AudioManager {
//   constructor(audioSrc, isLoop = true) {
//     this.audio = new Audio(audioSrc);
//     this.audio.loop = isLoop;
//     // this.audio.volume = 0.5; // 设置默认音量为 0.5
//   }

//   play() {
//     if (this.audio.paused) {
//       this.audio.play();
//     }
//   }

//   pause() {
//     if (!this.audio.paused) {
//       this.audio.pause();
//     }
//   }

//   stop() {
//     this.audio.pause();
//     this.audio.currentTime = 0; // 重置播放位置
//   }

//   setVolume(volume) {
//     if (volume >= 0 && volume <= 1) {
//       this.audio.volume = volume;
//     }
//   }
// }

// class AudioManager {
//   constructor() {
//     this.audioContext = new (window.AudioContext ||
//       window.webkitAudioContext)();
//     this.gainNode = this.audioContext.createGain();
//     this.gainNode.connect(this.audioContext.destination);
//     this.audioElements = {};
//     this.gainNode.gain.value = 0;
//     this.muted = true; // 默认静音
//     this.audioContextUnlocked = false;
//   }

//   unlockAudioContext() {
//     if (this.audioContext.state === 'suspended' && !this.audioContextUnlocked) {
//       this.audioContext
//         .resume()
//         .then(() => {
//           this.audioContextUnlocked = true;
//           console.log('AudioContext unlocked');
//         })
//         .catch((error) => {
//           console.error('Failed to unlock AudioContext:', error);
//         });
//     }
//   }

//   play(id, url) {
//     this.unlockAudioContext();

//     if (this.audioElements[id]) {
//       this.audioElements[id].audioElement.pause();
//       this.audioElements[id].audioElement.src = '';
//       delete this.audioElements[id];
//     }

//     let audioElement = new Audio(url);
//     let sourceNode = this.audioContext.createMediaElementSource(audioElement);
//     sourceNode.connect(this.gainNode);
//     audioElement.play();

//     this.audioElements[id] = {
//       audioElement,
//       sourceNode,
//     };

//     audioElement.onended = () => {
//       delete this.audioElements[id];
//     };
//   }

//   stopAudio(id) {
//     if (this.audioElements[id]) {
//       this.audioElements[id].audioElement.pause();
//       this.audioElements[id].audioElement.src = '';
//       delete this.audioElements[id];
//     }
//   }

//   stopAll() {
//     for (let id in this.audioElements) {
//       this.stopAudio(id);
//     }
//     this.audioContext.suspend();
//   }

//   toggleMute() {
//     this.muted = !this.muted;
//     this.gainNode.gain.value = this.muted ? 0 : 1;
//   }
// }

// // const audioManager = new AudioManager();
// // export default audioManager;
// export default new AudioManager();

import buttonNext from './assets/media/button-next.mp3';
import buttonPrevious from './assets/media/button-previous.mp3';

class AudioManager {
  constructor() {
    this.audioContext = null;
    this.audios = {};
    this.muted = true;
    this.masterGainNode = null;
  }

  initialize() {
    if (!this.audioContext) {
      this.audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
      this.masterGainNode = this.audioContext.createGain();
      this.masterGainNode.connect(this.audioContext.destination);
      if (this.muted) {
        this.masterGainNode.gain.value = 0;
      }
    }
  }

  play(id, url, loop = false) {
    this.initialize();
    if (this.audios[id]) {
      this.audios[id].audio.pause();
      this.audios[id].audio.currentTime = 0;
    }

    if (this.muted) return;

    const gainNode = this.audioContext.createGain();
    const audio = new Audio(url);
    audio.loop = loop;
    const track = this.audioContext.createMediaElementSource(audio);
    track.connect(gainNode);
    gainNode.connect(this.masterGainNode);
    // if (id === 'bg') gainNode.gain.value = 0.2;
    audio.play().catch((error) => {
      console.log('Audio play error:', error);
    });
    this.audios[id] = { audio, gainNode };

    // audio.onended = () => {
    //   if (callback) callback();
    // };
  }

  playButtonNext(callback = null, sound = null, timeout = 200) {
    if (sound) {
      this.play('button-next', sound);
    } else {
      this.play('button-next', buttonNext);
    }
    if (callback) {
      setTimeout(() => {
        callback();
      }, timeout);
    }
  }
  playButtonPrevious(callback = null) {
    this.play('button-next', buttonPrevious);
    if (callback) {
      setTimeout(() => {
        callback();
      }, 200);
    }
  }

  stop(id) {
    if (this.audios[id]) {
      this.audios[id].audio.pause();
      this.audios[id].audio.currentTime = 0;
      delete this.audios[id];
    }
  }

  stopAll() {
    for (const id in this.audios) {
      this.audios[id].audio.pause();
      this.audios[id].audio.currentTime = 0;
    }
    this.audios = {};
  }

  unlockAudioContext() {
    this.initialize();
    this.audioContext
      .resume()
      .then(() => {
        console.log('AudioContext resumed');
      })
      .catch((error) => {
        console.log('AudioContext resume error:', error);
      });
  }

  muteAll() {
    // for (const id in this.audios) {
    //   this.audios[id].muted = true;
    // }
    this.masterGainNode.gain.value = 0;
    this.muted = true;
  }

  unmuteAll() {
    // for (const id in this.audios) {
    //   this.audios[id].muted = false;
    // }
    this.masterGainNode.gain.value = 1;
    this.muted = false;
  }

  toggleMute() {
    this.muted = !this.muted;
    this.masterGainNode.gain.value = this.muted ? 0 : 1;
    return this.muted ? 0 : 1;
  }

  setMasterVolume(volume) {
    this.masterGainNode.gain.value = volume;
    this.muted = volume === 0;
  }

  setAudioTrackVolume(id, volume) {
    const audio = this.audios[id];
    if (audio) {
      audio.gainNode.gain.value = volume;
    }
  }

  isMuted() {
    return this.muted;
  }
}

export default new AudioManager();
