<template>
    <div class="container">
        <audio-icon />
        <div class="container-text">
            <p>{{ text }}</p>
            <img src="@game/assets/img/arrow.png" alt="" @click="onClick">
        </div>
    </div>
</template>

<script>
import { tLevel1 } from '@game/utils';
import running from '@game/assets/media/level01/running.mp3';
import { audioMixinLevel1 } from '@game/mixins/audioMixin.js';

export default {
    name: 'Scene1',
    mixins: [audioMixinLevel1],
    data() {
        return {
            text: tLevel1('scene1.text')
        }
    },
    mounted() {
        this.$audioManager.play('running', running)
        setTimeout(() => {
            this.$audioManager.stop('running')
        }, 1000);
    },
    beforeDestroy() {
        this.$audioManager.stop('running')
    },
    methods: {
        onClick() {
            this.$audioManager.playButtonNext(() => {
                this.$router.push({ name: 'L1Scene2' })
            })
        }
    }
}

</script>

<style lang="scss" scoped>
.container {
    background-image: url(@game/Level01/assets/img/alex-3.jpg);

    .container-text {
        padding-left: 60px;
        padding-right: 60px;
        min-height: 120px;
    }
}
</style>