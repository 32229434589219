<template>
  <div ref="lottieContainer" class="lottie-container"></div>
</template>

<script>
import lottie from 'lottie-web';

export default {
  name: 'LottieAnimation',
  props: {
    animationData: {
      type: Object,
      required: true,
    },
    speed: {
      type: Number,
      default: 1,
    }
  },
  data() {
    return {
      animation: null,
    }
  },
  mounted() {
    this.loadAnimation();
  },
  methods: {
    loadAnimation() {
      this.animation = lottie.loadAnimation({
        container: this.$refs.lottieContainer,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: this.animationData,
      });
      this.animation.setSpeed(this.speed)
    },
  },
};
</script>

<style scoped>
.lottie-container {
  width: 100%;
  height: 100%;
}
</style>
