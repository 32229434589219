<template>
  <div class="container">
    <AudioIcon />
    <h3 class="title">{{ title }}</h3>
    <div class="text-wrapper">
      <div class="wrapper">
        <img src="@game/Level04/assets/img/troy.png" alt="" class="troy-img">
        <div class="troy-container">
          <h4 class="troy-title">{{ troyTitle }}</h4>
          <p class="troy-text">{{ troyText }}</p>
        </div>
        <img src="@game/Level04/assets/img/djvu1.png" alt="" class="djvu-img">
        <div class="djvu-container">
          <h4 class="djvu-title">{{ djvuTitle }}</h4>
          <p class="djvu-text">{{ djvuText }}</p>
        </div>
      </div>
      <div class="button" @click="onClick">{{ buttonText }}</div>
    </div>
  </div>
</template>

<script>
import { tLevel4 } from '@game/utils';
import { audioMixinLevel42 } from '@game/mixins/audioMixin.js';

export default {
  name: 'Scene7',
  mixins: [audioMixinLevel42],
  data() {
    return {
      title: tLevel4('scene6.title'),
      troyTitle: tLevel4('scene8.troyTitle'),
      troyText: tLevel4('scene8.troyText'),
      djvuTitle: tLevel4('scene8.djvuTitle'),
      djvuText: tLevel4('scene8.djvuText'),
      buttonText: tLevel4('scene8.buttonText'),
    }
  },
  methods: {
    onClick() {
      this.$audioManager.playButtonNext(() => {
        this.$router.push({ name: 'L4Scene9' })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  /* animation */
  position: absolute;

  width: 100%;
  height: 100%;
  background-image: url(@game/Level04/assets/img/kepu.jpg);
  background-size: contain;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h3,
  h4,
  p,
  img {
    margin: 0;
    padding: 0;
  }

  .title {
    position: absolute;
    bottom: 710px;
    box-sizing: border-box;
    padding: 5px 15px;
    border: .2rem solid #fff;
    border-radius: 1.2rem;
    background: #66a261;
    color: #fff;
    font-weight: normal;
  }

  .text-wrapper {
    box-sizing: border-box;
    width: 70%;
    position: absolute;
    top: 130px;
    bottom: 125px;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    .wrapper {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      height: 90%;
      overflow-y: auto;

      .troy-container,
      .djvu-container {
        width: 100%;

        .troy-title,
        .djvu-title {
          color: #66a261;
        }
      }

      .troy-img {
        height: 5rem;
      }

      .djvu-img {
        height: 5rem;
      }
    }

    .button {
      margin-top: 1rem;
      background-color: #66a261;
      color: #fff;
      width: 6rem;
      height: 2rem;
      border-radius: .5rem;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
</style>