<template>
    <div class="overlay">
        <div class="dialog">
            <div class="header">
                <div class="little-circle"></div>
                <div class="little-circle"></div>
                <div class="little-circle"></div>
            </div>
            <div class="content">
                <div class="error-icon">
                    <div class="cross"></div>
                </div>
                <p class="dialog-tip">{{ dialogTip }}</p>
                <!-- <div class="button" @click="onCloseDialog">{{ buttonText }}</div> -->
                <button class="button" @click="closeCallback">{{ buttonText }}</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PasswordErrorBox',
    props: {
        dialogTip: {
            type: String,
            required: true,
        },
        buttonText: {
            type: String,
            required: true,
        },
        closeCallback: {
            type: Function,
            required: true,
        }
    },
}
</script>

<style lang="scss" scoped>
.overlay {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, .5);

    .dialog {
        width: 15rem;
        min-height: 15rem;
        box-sizing: border-box;
        border: 2px solid #b82e48;
        border-radius: 10px;
        background-color: #fff;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 20%;
        overflow: hidden;

        .header {
            height: 25px;
            background-color: #b82e48;
            display: flex;
            align-items: center;
            gap: 10px;

            .little-circle {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: #fff;
            }

            .little-circle:first-child {
                margin-left: 15px;
            }
        }

        .content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            box-sizing: border-box;
            padding: 15px 20px;

            .error-icon {
                width: 80px;
                height: 80px;
                background-color: #c91745;
                border-radius: 50%;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;

                .cross {
                    width: 60%;
                    /* Cross width */
                    height: 60%;
                    /* Cross height */
                    position: relative;
                    transform: rotate(45deg);
                    /* Rotate the cross */

                    &::before,
                    &::after {
                        content: '';
                        position: absolute;
                        background-color: white;
                    }

                    &::before {
                        width: 100%;
                        /* Horizontal bar width */
                        height: 30%;
                        /* Horizontal bar height */
                        top: 35%;
                        /* Center the horizontal bar */
                        left: 0;
                    }

                    &::after {
                        width: 30%;
                        /* Vertical bar width */
                        height: 100%;
                        /* Vertical bar height */
                        top: 0;
                        left: 35%;
                        /* Center the vertical bar */
                    }
                }
            }

            .dialog-tip {
                font-size: 1.2rem;
                color: #343b34;
                text-align: center;
            }

            .button {
                max-width: 98%;
                background-color: #50a459;
                border-radius: .4rem;
                padding: 5px 10px;
                color: #fff;
                text-align: center;
                border: none;
                cursor: pointer;

                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }
}
</style>