<template>
  <div class="container">
    <AudioIcon />
    <h3 class="head">{{ headTitle }}</h3>
    <div class="content">
      <div class="member animate__animated animate__fadeIn delay-500ms">
        <img src="@game/assets/img/level05/b.png" alt="" class="avatar">
        <div class="info">
          <div class="title">{{ b }}</div>
          <div class="text">{{ bText1 }} &#129309;</div>
        </div>
      </div>
      <div class="member animate__animated animate__fadeIn delay-1">
        <img src="@game/assets/img/level05/b.png" alt="" class="avatar">
        <div class="info">
          <div class="title">{{ b }}</div>
          <div class="text">{{ bText2 }}<img src="@game/assets/img/level05/screenshot-2.png" alt="" /></div>
        </div>
      </div>
      <div class="member animate__animated animate__fadeIn delay-2">
        <img src="@game/assets/img/level05/frank.png" alt="" class="avatar">
        <div class="info">
          <div class="title">{{ frank }}</div>
          <div class="text">{{ frankText }}</div>
        </div>
      </div>
      <div class="member animate__animated animate__fadeIn delay-3">
        <img src="@game/assets/img/level05/a.png" alt="" class="avatar">
        <div class="info">
          <div class="title">{{ a }}</div>
          <div class="text">{{ aText }}</div>
        </div>
      </div>
      <div class="member animate__animated animate__fadeIn delay-4">
        <img src="@game/assets/img/level05/c.png" alt="" class="avatar">
        <div class="info">
          <div class="title">{{ c }}</div>
          <div class="text">{{ cText }}</div>
        </div>
      </div>

      <div class="dialog" v-if="isDialogShow">
        <div class="option-a" @click="onSelectA" :style="styleA">{{ optionA }}</div>
        <div class="option-b" @click="onSelectB" :style="styleB">{{ optionB }}</div>
      </div>
    </div>
    <div class="footer" :style="pseudoElementStyle" @click="onForward">
      <img src="@game/assets/img/level05/reply-box.png" alt="">
    </div>
  </div>
</template>

<script>
import { tLevel5 } from '@game/utils';
import selectSound from '@game/assets/media/level05/select.mp3';
import { audioMixinLevel5 } from '@game/mixins/audioMixin.js';

export default {
  name: 'Scene9',
  mixins: [audioMixinLevel5],
  props: {
    isShow: {
      type: Boolean,
    }
  },
  data() {
    return {
      headTitle: tLevel5('headTitle'),
      b: tLevel5('members.b'),
      bText1: tLevel5('scene9.bText1'),
      bText2: tLevel5('scene9.bText2'),
      frank: tLevel5('members.frank'),
      frankText: tLevel5('scene9.frankText'),
      a: tLevel5('members.a'),
      aText: tLevel5('scene9.aText'),
      c: tLevel5('members.c'),
      cText: tLevel5('scene9.cText'),

      optionA: tLevel5('scene9.dialog.a'),
      optionB: tLevel5('scene9.dialog.b'),
      isDialogShow: this.isShow,
      isSelectedA: false,
      isSelectedB: false
    }
  },
  computed: {
    pseudoElementStyle() {
      return this.isDialogShow ? { '--pseudo-display': 'block' } : { '--pseudo-display': 'none' };
    },
    styleA() {
      if (this.isSelectedA) {
        return {
          backgroundColor: '#538adb',
          outline: '2px solid #fff',
        }
      } else {
        return {
          backgroundColor: '#ffffff',
          outline: 'none',
        }
      }
    },
    styleB() {
      if (this.isSelectedB) {
        return {
          backgroundColor: '#538adb',
          outline: '2px solid #fff',
        }
      } else {
        return {
          backgroundColor: '#ffffff',
          outline: 'none',
        }
      }
    }
  },
  methods: {
    onSelectA() {
      this.$audioManager.play('other', selectSound)
      this.isSelectedB = false
      this.isSelectedA = true
    },
    onSelectB() {
      this.$audioManager.play('other', selectSound)
      this.isSelectedA = false
      this.isSelectedB = true
    },
    onForward() {
      if (!this.isDialogShow) {
        this.isDialogShow = true;
        return;
      }

      this.$store.commit('completeTask')
      this.$audioManager.playButtonNext(() => {
        if (this.isSelectedA) {
          this.$router.push({ name: 'L5Scene10' })
        } else if (this.isSelectedB) {
          this.$router.push({ name: 'L5Scene15' })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  /* animation */
  position: absolute;

  width: 100%;
  height: 100%;
  background-image: url(@game/assets/img/level05/scene-4-bg.jpg);
  background-size: contain;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;


  .head {
    width: 100%;
    height: 60px;
    background-color: #8fcef5;
    border-radius: 20px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    color: #1d3082;
    font-weight: normal;
    margin: 0;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content {
    box-sizing: border-box;
    width: 100%;
    padding: 30px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    gap: 25px;
    position: relative;

    .member {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 10px;

      img {
        margin-top: -5px;
        width: 50px;
      }

      .info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        align-self: flex-end;
        gap: 10px;

        .title {
          color: #aad8f7;
        }

        .text {
          box-sizing: border-box;
          background-color: #afdffb;
          color: #334b94;
          padding: 8px 15px;
          border-radius: 10px;
        }
      }
    }

    .member:nth-child(2) .text {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      padding: 8px 10px;

      img {
        margin-top: 5px;
        width: 130px;
      }
    }

    .dialog {
      width: 100%;
      height: 220px;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .option-a,
      .option-b {
        flex: 1;
        box-sizing: border-box;
        border-radius: 15px;
        margin-top: 10px;
        padding: 15px 18px;
        width: 80%;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
          opacity: .8;
        }
      }

    }
  }

  .footer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    --pseudo-display: block;

    img {
      width: 100%;
    }

    &:hover {
      opacity: .8;
    }

    &::after {
      content: '';
      display: var(--pseudo-display);
      width: 45px;
      height: 45px;
      background-image: url(@game/assets/img/level05/arrow-upload.png);
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      position: absolute;
      top: 20px;
      right: 35px;
    }
  }
}
</style>