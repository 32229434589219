import { render, staticRenderFns } from "./Scene8.vue?vue&type=template&id=6f87493f&scoped=true"
import script from "./Scene8.vue?vue&type=script&lang=js"
export * from "./Scene8.vue?vue&type=script&lang=js"
import style0 from "./Scene8.vue?vue&type=style&index=0&id=6f87493f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f87493f",
  null
  
)

export default component.exports