<template>
  <div class="container">
    <AudioIcon />
    <div class="wrapper">
      <p class="text">{{ text }}</p>
      <div class="button" @click="onForward">{{ buttonText }}</div>
    </div>
  </div>
</template>

<script>
import { tLevel5 } from '@game/utils';
import { audioMixinLevel5 } from '@game/mixins/audioMixin.js';

export default {
  name: 'Scene15',
  mixins: [audioMixinLevel5],
  data() {
    return {
      text: tLevel5('scene17.text'),
      buttonText: tLevel5('scene17.buttonText'),
    }
  },
  methods: {
    onForward() {
      this.$audioManager.playButtonNext(() => {
        this.$router.push({ name: 'L5Scene18' })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  /* animation */
  position: absolute;

  width: 100%;
  height: 100%;
  background-image: url(@game/assets/img/level05/scene-17-bg.jpg);
  background-size: contain;
  background-repeat: no-repeat;

  display: flex;
  justify-content: center;
  align-items: flex-end;

  .wrapper {
    width: 83%;
    box-sizing: border-box;
    border: 2px solid #68a364;
    background-color: rgba(255, 255, 255, .8);
    padding: 30px 20px;
    padding-bottom: 15px;
    border-radius: 15px;
    margin: 0;
    margin-bottom: 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .text {
      padding: 0;
      margin: 0;
    }

    .button {
      margin-top: 30px;
      background-color: #66a261;
      color: #fff;
      padding: 5px 10px;
      border-radius: 8px;
      cursor: pointer;

      &:hover {
        opacity: .8;
      }
    }

  }
}
</style>